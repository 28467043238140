import React, { useEffect, useState } from "react";
import { Section } from '../shared/customHtml';
import { useNavigate } from "react-router-dom";
import { generalService } from "../services/general.service";
import { useQueryString } from "./apartmentsTypes";

export const ApartmentDetails = () => {
    const queryString = useQueryString();
    const navigate = useNavigate();

    const [apartment, setApartment] = useState();

    useEffect(() => {
        let _data = generalService.getDecodedDataBase64(queryString.q);
        if (!_data || !_data.info || !_data.item) {
            navigate('/apartamente');
        }
        setApartment(_data);
    }, []);

    if (!apartment) {
        return null;
    }

    const image = <>
        <div className="full-page-image-container">
            <img src={apartment.info.introImage} className="full-page-image" alt="page image" />
            <div className="apartment-details-container align-l align-b">
                <div>
                    <div className="title">Ap. {apartment.item.number}</div>
                </div>
                {apartment.item.floor && <div>
                    <div>ETAJ</div>
                    <div>{apartment.item.floor}</div>
                </div>}
                {apartment.item.rooms && <div>
                    <div>NR. CAMERE</div>
                    <div>{apartment.item.rooms}</div>
                </div>}
                {apartment.item.totalSurface && <div>
                    <div>S. TOTAL</div>
                    <div>{apartment.item.totalSurface}</div>
                </div>}
                {apartment.item.usefulSurface && <div>
                    <div>S. UTIL</div>
                    <div>{apartment.item.usefulSurface}</div>
                </div>}
                {apartment.item.balconySurface && <div>
                    <div>S. BALCON</div>
                    <div>{apartment.item.balconySurface}</div>
                </div>}
            </div>
        </div>
    </>;

    return (
        <div>
            <Section>
                {image}
            </Section>

            <div className="plan-image">
                <img src={apartment.item.planImage} className="auto-page-image" alt="page image" />
            </div>
        </div>
    );
}