import React, { useEffect, useState } from "react";
import { ElementIcon } from '../shared/customHtml';
import { generalService } from '../services/general.service';
import { useNavigate } from "react-router-dom";

export const Footer = (props) => {
    const navigate = useNavigate();

    const handleGoTop = () => {
        generalService.setScrollTop(0);
    }

    const goTo = (url) => {
        navigate(url);
    }

    const phone = generalService.formatPlusPhoneNumber(window.datasets?.contact?.phone);
    const email = window.datasets?.contact?.email;
    const facebook = window.datasets?.links?.facebook;
    const instagram = window.datasets?.links?.instagram;

    return (<>
        <div className="footer align-p">
            <div className="row m-0 p-0">
                <div className="col-4">
                    <a onClick={() => goTo("/")}>
                        <img className="logo" src="/images/symbol-gold.svg" alt="DYMY PROPERTIES" />
                    </a>
                </div>
                <div className="col-8 text-end">
                    <a href={facebook} target="_blank" className="me-3 d-tablet-desktop">
                        <img className="option" src="/images/facebook-icon.svg" alt="facebook" />
                    </a>
                    <a href={instagram} target="_blank" className="me-5 d-tablet-desktop">
                        <img className="option" src="/images/instagram-icon.svg" alt="instagram" />
                    </a>
                    <a onClick={handleGoTop}>
                        <img className="option" src="/images/arrow-button-black.svg" alt="..." />
                    </a>
                </div>

                <div className="col-12 text-end mt-3 d-mobile">
                    <a href={facebook} target="_blank" className="me-3">
                        <img className="option" src="/images/facebook-icon.svg" alt="facebook" />
                    </a>
                    <a href={instagram} target="_blank" className="">
                        <img className="option" src="/images/instagram-icon.svg" alt="instagram" />
                    </a>
                </div>
            </div>

            <div className="row m-0 p-0">
                <div className="col-lg-8 col-12">
                    <div className={"footer-menu"} >
                        <div className="row m-0 p-0 ">
                            <div className="col-md-4 col-12 p-0">
                                <ul className="list-group">
                                    <li className="list-group-item"><span className="footer-title">HARTA SITE</span></li>
                                    <li className="list-group-item"><a onClick={() => goTo("/")}>Home</a></li>
                                    <li className="list-group-item"><a onClick={() => goTo("/apartamente")}>Apartamente</a></li>
                                    <li className="list-group-item"><a onClick={() => goTo("/galerie")}>Galerie</a></li>
                                    <li className="list-group-item"><a onClick={() => goTo("/contact")}>Contact</a></li>
                                </ul>
                            </div>
                            <div className="col-md-4 col-12 p-0">
                                <ul className="list-group">
                                    <li className="list-group-item"><span className="footer-title">ADRESA</span></li>
                                    <li className="list-group-item">Str. Budapesta 1C</li>
                                    <li className="list-group-item">Tomis Plus</li>
                                    <li className="list-group-item">Constanta</li>
                                </ul>
                            </div>
                            <div className="col-md-4 col-12 p-0">
                                <ul className="list-group">
                                    <li className="list-group-item"><span className="footer-title">CONTACT</span></li>
                                    <li className="list-group-item">Tel: {phone}</li>
                                    <li className="list-group-item">{email}</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="footer-policies">
                        <span>© 2023 DYMY Properties</span>
                        <a onClick={() => goTo("/confidentialitate")}>Politica de confidențialitate</a>
                        <a onClick={() => goTo("/termeniconditii")}>Termeni și condiții</a>
                        <a onClick={() => goTo("/cookie")}>Politica Cookie</a>
                    </div>

                </div>
            </div>

            <div className="position-relative d-desktop-4k">
                <ElementIcon rows={6} className="element-icon-br" />
            </div>
        </div>
    </>
    );
}