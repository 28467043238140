import React from "react";
import { Section, FullPageImage, TermsParagraph } from '../shared/customHtml';
import { generalService } from '../services/general.service';

export const TermsAndConditions = () => {
    const introImage = window.datasets?.homePage?.introImage;
    const phone = generalService.parsePhoneNumber(window.datasets?.contact?.phone);
    const phoneDisplay = generalService.formatPlusPhoneNumber(phone);
    const email = window.datasets?.contact?.email;

    return (
        <div>
            <Section>
                <FullPageImage
                    src={introImage}
                    overlay={true}
                    home={true}
                    title={"Termeni și condiții"}
                />
            </Section>

            <div className="terms align-p">
                <TermsParagraph
                    title="Cine suntem"
                >
                    <div className="mb-3">DYMY BUILDING S.R.L., cu sediul social în Municipiul Constanta, Strada Rotterdam, Nr. 2, Etaj Parter, Ap. 4, Judet Constanta, inregistrata la oficiul registrul comertului sub numarul J13/2661/2016, CUI: RO36726188, email: {email}, numar telefon: <a href={`tel:${phone}`}>{phoneDisplay}</a> (denumita in continuare „societatea” sau „platforma” sau „operatorul”) este operator de date cu carcater personal.</div>
                    <div className="mb-3">Prezenta politica are rolul de informare a persoanelor vizate cu privire la conditiile in care sunt procesate datele cu caracter personal de catre platforma. Aceasta politica este valabila pentru toate activitatile specifice ale societatii realizate prin intermediul urmatoarelor site-urilor noastre.</div>
                    <div className="mb-3">Folosirea serviciilor oferite de societate se poate face doar dupa luarea la cunostinta a acestor politici.</div>
                    <div>Platforma nu este destinata minorilor sub 16 ani si nu poate fi utilizata de acestia.</div>
                </TermsParagraph>

                <TermsParagraph
                    title="Definitii"
                >
                    In cadrul prezentei politici, termenii mentionati vor avea sensurile specificate mai jos:
                    <ul>
                        <li>
                            ”date cu caracter personal ale beneficiarului/ reprezentantului legal/ reprezentantului conventional” – toate datele cu caracter personal, indiferent de forma sau mediul lor, care sunt: I) furnizate fie de beneficiar, fie de reprezentantul legal sau conventional al beneficiarului ii) furnizate, provenite, aduse la cunostinta an scris sau verbal de catre asiguratorul autovehicului supus reparatiei sau un alt asigurator direct implicat sau de catre institutiile/autoritatile/entitatile publice (sectii de politie, parchete de pe langa instante, instante etc.);
                        </li>
                        <li>
                            ”autoritate de supraveghere” – inseamna o autoritate publica independenta instituita de un stat membru avand competenta de supraveghere a protectiei datelor cu caracter personal din ue in a carei jurisdictie isi are sediul si efectueaza prelucrarea datelor cu caracter personal platforma, in calitate de operator, sau persoana imputernicita de catre platforma;
                        </li>
                        <li>
                            ”prelucrare” – inseamna orice operatiune sau set de operatiuni efectuate asupra datelor cu caracter personal sau asupra seturilor de date cu caracter personal, cu sau fara utilizarea de mijloace automatizate, cum ar: colectarea, inregistrarea, organizarea, structurarea, stocarea, adaptarea sau modificarea, extragerea, consultarea, utilizarea, divulgarea prin transmitere, diseminarea sau punerea in orice alt mod, alinierea sau combinarea, restrictionarea, stergerea sau distrugerea;
                        </li>
                        <li>
                            ”operator” – persoana juridica, cum este in situatia de fata platforma, sau persoana fizica care, singura sau impreuna cu altele, stabileste scopurile si mijloacele de prelucrarea datelor cu caracter personal;
                        </li>
                        <li>
                            „consimtamant” al persoanei vizate - inseamna orice manifestare de vointa libera, specifica, informata si lipsita de ambiguitate a persoanei vizate prin care aceasta accepta, printr-o declaratie sau printr-o actiune fara echivoc, ca datele cu caracter personal care o privesc sa fie prelucrate;
                        </li>
                        <li>
                            „incalcarea securitatii datelor cu caracter personal” - inseamna o incalcare a securitatii care duce, in mod accidental sau ilegal, la distrugerea, pierderea, modificarea, sau divulgarea neautorizata a datelor cu caracter personal transmise, stocate sau prelucrate intr-un alt mod, sau la accesul neautorizat la acestea;
                        </li>
                        <li>
                            ”regulament” inseamna regulamentul (ue) 2016/679 privind protectia persoanelor fizice in ceea ce priveste prelucrarea datelor cu caracter personal si privind libera circulatie a acestor date si de abrogare a directivei 95/46/ce.
                        </li>
                    </ul>
                </TermsParagraph>

                <TermsParagraph
                    title="Prevederi cu caracter general"
                >
                    <div className="mb-3">Intrucat pretuim confientialitatea informatiilor dvs., operatorul se obliga sa respecte dispozitiile prezentei politici, precum si prevederile mentionate in regulament si ale normelor prevazute in dreptul intern in ceea ce priveste prelucrarea datelor cu caracter personal, securitatea si confidentialitatea acestora.</div>
                    <div>In cazul in care vom modifica aceasta politica, va vom anunta pe aceasta pagina si va vom informa (ex. Prin e-mail sau prin intermediul platformei). Daca nu sunteti de acord cu politica noastra de confidentialitate a datelor sau nu veti fi de acord cu modificarile acesteia, va puteti inchide contul accesand setarea contului si selectand stergerea contului sau puteti solicita stergerea anuntului postat si stergerea datelor cu caracter personal.</div>
                </TermsParagraph>

                <TermsParagraph
                    title="Obiectul, durata, natura, scopul, tipul de date cu caracter personal prelucrate"
                >
                    <div className="mb-3">Datele personale inseamna orice date sau informatii care ne ajuta sa va putem identifica direct (exemplu: numele, prenumele tau) sau indirect (exemplu: in functie de profilul pe care il cream pentru a-ti transmite oferte personalizate). Anumite informatii sunt mai putin evidente (cum ar fi preferintele si obiceiurile tale la cumparaturi, ip-ul computerului tau), dar asociate cu persoana ta, ne ajuta sa te putem identifica si se circumscriu astfel notiunii de ”date personale”.</div>
                    <div className="mb-3">Datele sensibile se refera la date care includ detalii despre rasa sau etnia dvs., convingeri religioase sau filosofice, viata sexuala, orientarea sexuala, opinii politice, apartenenta la sindicate, informatii despre sanatate dvs. Si date genetice si biometrice. Nu colectam nicio informatie despre convingeri, viata sau orientare sexuala, opiniile politice, aparteneta la sindicate, informatii despre sanatate, date genetice sau biometrice sau despre infractiuni.</div>
                    <div className="mb-3">Date pe care le primim direct de la dvs:</div>
                    <div className="mb-3 text-semibold">Cand va deschideti un cont pe platforma</div>
                    <div>Puteti sa va deschideti un cont pe platforma doar daca ne oferiti urmatoarele date personale:</div>
                    <ul>
                        <li>Nume si prenume</li>
                        <li>Adresa de contact</li>
                        <li>E-mail</li>
                        <li>Numar de telefon</li>
                    </ul>
                    <div className="mb-3">Temeiul prelucrarii este stabilirea si inceperea unei relatii contratuale.</div>

                    <div className="mb-3 text-semibold">Cand postati un anunt pe platforma</div>
                    <div>De fiecare data cand postezi un anunt vom prelucra urmatoarele date:</div>
                    <ul>
                        <li>Nume si prenume</li>
                        <li>E-mail</li>
                        <li>Numar de telefon</li>
                    </ul>
                    <div className="mb-3">Temeiul prelucrarii este relatia contractuala.</div>

                    <div className="mb-3 text-semibold">Cand faceti plata unei facturi aferente serviciilor prestate de catre noi</div>
                    <div>De fiecare data cand efectuezi o plata catre noi putem prelucra urmatoarele date:</div>
                    <ul>
                        <li>Nume si prenume</li>
                        <li>Adresa</li>
                        <li>Cont bancar sau numar de card</li>
                    </ul>
                    <div className="mb-3">Temeiul prelucrarii este relatia contractuala.</div>

                    <div className="mb-3 text-semibold">Campanii promotionale</div>
                    <div>Pentru campaniile online derulate pe platofrma putem colecta, in functie de campanie, urmatoarele date necesare participarii tale:</div>
                    <ul>
                        <li>Nume si prenume</li>
                        <li>E-mail</li>
                        <li>Numar de telefon</li>
                    </ul>
                    <div>Temeiul prelucrarii este consimtamantul.</div>
                </TermsParagraph>

                <TermsParagraph
                    title="Contabilitate si raportari"
                >
                    <div className="mb-3">Prelucram datele tale (cele indicate in facturile fiscale) pentru mentinerea evidentelor contabile, pentru auditarea financiara anuala, precum si pentru depunerea declaratiilor fiscale si contabile la autoritatile fiscale.</div>
                    <div>Temeiul prelucrarii este relatia contractuala si obligatia legala.</div>
                </TermsParagraph>

                <TermsParagraph
                    title="Apararea drepturilor in justitie"
                >
                    <div className="mb-3">Atunci cand ne aparam drepturile in justitie pentru recuperarea unor sume datorate sau cand ne protejam interesele impotriva unor pretentii / reclamatii nejustificate, vom prelucra datele tale (furnizate noua de catre dvs) necesare formularii unor actiuni in instanta, alte cereri si documente specifice.</div>
                    <div>Temeiul prelucrarii este interesul nostru legitim.</div>
                </TermsParagraph>

                <TermsParagraph
                    title="Proceduri in fata autoritatilor"
                >
                    <div className="mb-3">Atunci cand suntem obligati conform unor prevederi legale, vom furniza autoritatilor si institutiilor competente datele pe care le detinem si care au solcitate in mod legal.</div>
                    <div>Temeiul prelucrarii este obligatia legala.</div>
                </TermsParagraph>

                <TermsParagraph
                    title="Asistenta clienti"
                >
                    <div className="mb-3">Vom putea prelucra vocea ta din înregistrările convorbirilor telefonice cu tine pentru a răspunde solicitărilor tale de asistență. Subliniem ca nu analizam in mod curent inregistrarile convorbirilor telefonice, ci exclusiv in situatia in care pot aparea reclamatii cu privire la serviciile acordate de noi.</div>
                    <div>Temeiul prelucrarii este interesul nostru legitim.</div>
                </TermsParagraph>

                <TermsParagraph
                    title="Date colectate in mod automat"
                >
                    <div className="mb-3">Crearea de profiluri de client in scop de marketing direct</div>
                    <div className="mb-3">In functie de interactiunile si comportamentul tau in platforma, putem colecta si prelucra prin intermediul partenerilor nostri (e.G google) datele pentru a crea profilul tau de client in vederea transmiterii ulterioare de oferte personalizate sau plasarea de anunturi promotionale (prin google sau faceook).</div>
                    <div>In vederea profilarii putem prelucra urmatoarele date:</div>
                    <ul>
                        <li>Pe baza comportamentului tau de navigare pe site ex.: anunturi vizualizate</li>
                        <li>Pe baza datelor demografice: varsta, sex, oras</li>
                        <li>Pe baza istoricului de activitate si a valorii monetare</li>
                    </ul>
                    <div className="mb-3">Este important sa retii ca te poti opune oricand profilarii prin simpla informare a noastra. Cu privire la targetare reclamei pr facebook si google.</div>
                    <div>Temeiul prelucrarii este consimtamantul.</div>
                </TermsParagraph>

                <TermsParagraph
                    title="Date colecatate prin intermediul tehnologiei cookie"
                >
                    <div>Atunci cand vizitezi platforma ca vizitator sau te autentifici in contul de client colectam cu ajutorul cookie-urilor si date obtinute de la computerul, telefonul, tableta sau alt dispozitiv utilizat de tine („dispozitivul”), informatii cu care te putem identifica online („identificatori online”), si pe care le utilizam la profilare in scop de marketing direct:</div>
                    <ul>
                        <li>Adresa IP</li>
                        <li>Browser-ul de internet pe care il utilizezi si versiunea sistemului de operare al dispozitivului</li>
                        <li>Date de protocol hhtp/https</li>
                        <li>Durata vizitei/ activitatii tale pe website-ul platformei</li>
                        <li>Locatia generala a dispozitivului (daca este activata geo-localizarea) de pe care te conectezi la site-ul platformei</li>
                    </ul>
                    <div className="mb-3">Este foarte important de stiut ca majoritatea dispozitivelor iti ofera optiunea de a dezactiva serviciile de geo-localizare chiar din setarile dispozitivului respectiv.</div>
                    <div className="mb-3">„cookie-uri” - sunt fisiere pe care un server le trimite dispozitivului tau, plasate pe site-ul platformei sau prin descarcarea. Modalitatea in care prelucram cookie-uri sunt detaliate in politica de utilizare a cookies, disponibila pe website-ul pltformei.</div>
                    <div>Temeiul prelucrarii este consimtamantul pe care ni l-ai acordat, cel mai probabil, prin intermediul unui pop-up, e-mail sau sms. Este importat sa retii ca iti poti retrage consimtamantul oricand.</div>
                </TermsParagraph>

                <TermsParagraph
                    title="Publicitate"
                >
                    <div className="mb-3">Pe baza navigarilor si comportamentului tau in platforma, monitorizat prin cookie-uri, iti putem furniza reclame on-line la unele dintre serviciile noastre care te pot interesa sau la unele dintre anunturile postate pe platforma. Pe baza informatiilor despre navigarea ta in platforma, te putem incadra intr-un grup de interes cu alti clienti similari, ca si comportament de navigare si iti vom livra astfel reclame (bannere) pe baza acelor interese.</div>
                    <div className="mb-3">Poti refuza setarea unui modul cookie prin setarea browser-ului care dezactiveaza in general setarea automata de module cookie. Poti, de asemenea, dezactiva modulele cookie pentru serviciile publicitare ale unui furnizor servicii browser (de ex. Google), setand browser-ul tau in asa fel incat modulele cookie ale domeniului respectiv sa fie blocate. Optiunea de a renunta la publicitatea comportamentala on-line nu va opri afisarea reclamelor generale, care nu sunt individualizate in functie de interesele tale.</div>
                    <div>Temeiul prelucrarii este consimtamantul pe care ni l-ai acordat, cel mai probabil prin intermediul unui pop-up, e-mail sau sms. Este importat sa retii ca iti poti retrage consimtamantul oricand.</div>
                </TermsParagraph>

                <TermsParagraph
                    title="Oferte personalizate"
                >
                    <div className="mb-3">Putem utiliza adresa ta de e-mail si numarul de telefon mobil pentru crearea de liste de clienti iar apoi vom folosi datele codificate din aceasta lista pentru a corela datele tale (si a altor clienti dintr-un grup de interes) cu datele persoanelor de pe facebook, pentru a transmite, pe acest canal, catre contul tau de facebook, oferte personalizate prin reclame tinta.</div>
                    <div className="mb-3">In plus, pe website-ul platformei utilizam si serviciile facebook de audienta personalizata creata cu ajutorul datelor pixelului tau. Cu ajutorul acestui serviciu oferit de facebook, platforma, cu ajutorul google, poate identifica faptul ca a-ti dat click pe anuntul nostru publicitar din facebook si ati fost redirectionat catre site-ul platformei. Datele colectate cu facebook servesc exclusiv la intocmirea de statistici privind succesul si utilizarea campaniilor noastre publicitare pe facebook.</div>
                    <div className="mb-3">Separat, daca ne vei aprecia pagina de facebook prin acordarea unui „like”, vom primi aceste informatii fara a le utiliza altfel decat pentru a putea comunica cu tine si eventual, pentru a-ti transmite unele oferte personalizate prin contul tau de facebook pe baza de audiente personalizate.</div>
                    <div>Temeiul prelucrarii este consimtamantul pe care ni l-ai acordat, cel mai probabil prin intermediul unui pop-up, e-mail sau sms. Este importat sa retii ca iti poti retrage consimtamantul oricand.</div>
                </TermsParagraph>

                <TermsParagraph
                    title="Securitate si mentenanta website"
                >
                    <div>In mod normal utilizam urmatorii identificatori online pentru mentenanta si securizarea website-ului platformei:</div>
                    <ul>
                        <li>Adresa IP</li>
                        <li>Browser-ul de internet pe care il utilizezi si versiunea sistemului de operare al dispozitivului cu care te conectezi</li>
                        <li>Date de protocol hhtp/https</li>
                        <li>Locatia dispozitivului (daca este activata geo-localizarea) de pe care te conectezi la site-ul platformei</li>
                    </ul>
                    <div>Aceste date sunt prelucrate pentru a asigura buna functionare a website-ului, respectiv:</div>
                    <ul>
                        <li>Afisarea corecta a continutului</li>
                        <li>Retinerea datelor tale de autentificare (atunci cand ne soliciti)</li>
                        <li>Imbunatatirea website-ului platformei</li>
                        <li>Parametrarea dispozitivului de pe care te conectezi/ autentifici la cerintele site-ului platformei</li>
                    </ul>
                    <div className="mb-3">A asigura securitatea website-ul si a te asigura ca te protejam impotriva fraudei sau a oricarei brese de securitate it in ceea ce priveste platforma.</div>
                    <div className="mb-3">Identificarea si remedierea defectiunilor care impiedica utilizarea website-ului nostru sau ale contului tau de client.</div>
                    <div>Temeiul prelucrarii este interesul nostru legitim pentru implementarea, parametrarea si mentinerea masurilor de securitate a website-ului/lor platformei.</div>
                </TermsParagraph>

                <TermsParagraph
                    title="Date primite de la terti"
                >
                    <div className="mb-3">Colectate automat de pe site-uri pe care sunt promovate anunturi imobiliare</div>
                    <div className="mb-3">Platforma detine o aplicatie ca scaneaza periodic anumite site-uri unde orice persoana interesata posteaza anunturi cu privire la intentia de vanzare sau inchiriere a unor imobile.</div>
                    <div className="mb-3">Aceasta aplicatie identifica, pe baza unor algoritmi prestabiliti, daca un anunt se afla sau nu in baza noastra de date si, in caz negativ, il copiaza in vederea preluarii pe platforma. Nu sunt copiate decat datele facute publice in mod expres de dvs., deobicei nume, numar telefon si adresa de email.</div>
                    <div>Temeiul prelucrarii este interesul nostru legitim de a crea o baza de date cat mai detaliata.</div>
                </TermsParagraph>

                <TermsParagraph
                    title="Cat timp iti prelucram datele"
                >
                    <div className="mb-3">Platforma prelucreaza datele tale pentru perioada necesara indeplinirii scopurilor pentru care acestea au fost colectate si conform politicilor noastre de retentie a datelor personale. In anumite cazuri, unele prevederi legale ne pot impune sau permite pastrarea datelor pentru o perioada mai indelungata.</div>
                    <div>Perioada de pastrare a datelor depinde in principal de urmatoarele:</div>
                    <ul>
                        <li>Pentru perioada de care avem nevoie de datele tale pentru a-ti oferi serviciile si a ne indeplini obligatiile fata de tine si pentru scopurile mentionate mai sus in prezenta politica;</li>
                        <li>Daca ai un cont de client deschis pe platforma, vom pastra datele tale cat timp acest cont de client este activ, dar nu mai mult de 5 (cinci) ani de la data ultimei autentificari in cont sau ultimei activitati din platforma; te vom notifica inainte de a inchide contul tau de client si a sterge toate datele asociate acestui cont de client;</li>
                        <li>Daca ti-ai exprimat consimtamantul pentru prelucrarea datelor pentru o perioada mai mare, vom mentine datele pe toata aceasta perioada, cu exceptia cazului in care iti retragi intre timp consimtamantul;</li>
                        <li>Daca apelezi numerele speciale de telefon asociate unor anunturi iar apelul telefonic este inregistrat, putem pastra inregistrarea convorbirii pentru o perioada maxima de 1 an.</li>
                    </ul>
                    <div className="mb-3">Obligatiile legale sau contractuale ne impun pastrarea datelor tale pentru o anumita perioada de timp, de exemplu perioadele prevazute de lege pentru apararea drepturilor noastre in justitie (cu titlu general, o perioada care sa acopere prescriptia).</div>
                    <div className="mb-3">Daca iti dezactivezi contul de client din platforma si ne soliciti in mod expres stergerea totala, datele asociate contului tau de client vor fi sterse sau anonimizate astfel incat sa nu mai poti fi identificat in sistemele noastre de evidenta a datelor, exceptand cazul in care suntem obligati sa pastram datele tale pentru o perioada mai lunga, in baza legii sau interesului nostru legitim.</div>
                    <div className="mb-3">In acest fel iti vom asigura controlul deplin asupra datelor tale personale. Vom colecta si prelucra din nou datele tale daca ni le vei oferi din nou in website-ul platformei.</div>
                    <div className="mb-3">Pentru scopurile pentru care ne-ai oferit consimtamantul la prelucrarea datelor, evidentiate in tabelul de mai sus, vom prelucra datele tale cu privire la respectivul scop pana cand iti retragi consimtamantul privind prelucrarea in scopul respectiv, cu exceptia cazului in care suntem obligati sa mentinem aceste date pentru o perioada mai lunga potrivit legii, pentru raportari la autoritatile publice, sau pentru a ne apara drepturile in justitie.</div>
                    <div>Te informam ca ne bazam pe consimtamantul tau atunci cand prelucram datele tale pentru a-ti transmite:</div>
                    <ul>
                        <li>Oferte personalizate pe baza de publicitate comportamentala online;</li>
                        <li>Oferte personalizate bazate pe profilarea pe care o facem in scop de marketing direct, prin canalele de comunicare sms, email si notificari push-up;</li>
                        <li>Oferte bazate pe audientele personalizate (pe liste de clienti) in facebook.</li>
                    </ul>
                    <div className="mb-3 text-semibold">Cui putem dezvalui datele</div>
                    <div className="mb-3">Datele tale personale pot fi transmise catre si pot fi prelucrate de partenerii nostri de incredere pentru a-ti fi furnizate serviciile.</div>
                    <div className="mb-3">Pentru a livra serviciile in platforma, vom partaja datele tale cu partenerii nostri de incredere. Selectam foarte atent partenerii si furnizorii care efectueaza, in numele nostru, operatiuni suport activitatii noastre. Impartasim cu acestia doar datele personale necesare realizarii activitatilor specifice pe care le incredintam acestora. Datele preluate prin intermediul softului nostru de centraizare a anunturilor vor fi facute publice pe platfroma intrucat sunt colectate, la randul lor, din surse publice.</div>
                    <div>Atunci cand externalizam anumite activitati catre partenerii nostri de incredere depunem toate eforturile rezonabile pentru a ne verifica in prealabil daca acestia asigura protectia datelor tale prin masuri stricte de securitate a datelor si vom incheia cu fiecare contracte de prelucrare a datelor. In detaliu, transmitem unele date catre terte parti (furnizorii si partenerii nostri) pentru realizarea functiilor si serviciilor necesare operarii activitatilor platformei, cum ar fi:</div>
                    <ul>
                        <li>Furnizorul care ne asigura servicii de stocare a datelor pe servere externe situat in ue;</li>
                        <li>Furnizorul serviciilor de comunicari si transmiteri corespondenta pe e-mail situat in sua, care detine certificarea privacy shield (scutul de confidentialitate ue-sua);</li>
                        <li>Agentii imobiliare ce sunt abonate la platforma in vederea preluarii si promovarii bunurilor dvs.</li>
                    </ul>
                    <div className="mb-3">Impartasim datele tale cu partenerii nostri si in scopuri de marketing sau pentru a putea efectua marketing</div>
                    <div className="mb-3">Pentru a-ti oferi servicii personalizate intereselor si preferintelor tale, prelucram anumite date cu unii parteneri care ne ajuta sa va adresam ofertele personalizate, cum ar fi: (I) prelucrarea datelor prin platforma facebook pentru a-ti transmite oferte personalizate prin reclame tinta cu ajutorul functiei de audiente personalizate pe liste de clienti, sau (ii) prelucrarea datelor pentru a-ti transmite ofertele personalizate bazate pe publicitatea comportamentala online derulate cu google adwords. Modul in care facebook sau google prelucreaza datele tale este prezentat in aceasta politica de prelucrare a datelor.</div>
                    <div className="mb-3 text-semibold">Transmiterea datelor catre autoritati si institutii publice sau organe judiciare</div>
                    <div className="mb-3">Putem transmite unele din datele tale personale autoritatilor sau institutiilor publice competente, atunci cand ne impune legea (de ex. Investigarea fraudelor; prevenirea spalarii banilor; depunerea declaratiilor, situatiilor financiare la autoritatile fiscale etc.), sau putem transmite aceste date catre instantele judiciare atunci cand ne aparam in justitie, sau in fata altor autoritati publice.</div>
                    <div className="mb-3 text-semibold">Accesul auditorilor si consultantilor</div>
                    <div>Putem transmite unele din datele tale personale furnizorilor de servicii de contabilitate, juridice, resurse umane, audit, servicii bancare.</div>
                </TermsParagraph>

                <TermsParagraph
                    title="Transferuri internationale"
                >
                    <div className="mb-3">Ca regula, datele tale nu sunt stocate intr-o tara situata in afara uniunii europene sau spatiului economic european („see”). Totusi, unele date pot fi transferate catre partenerii nostri care ne ajuta sa operam activitatile platformei si sunt situati in afara spatiului uniunii europene. Insa, cu fiecare dintre acesti parteneri am depus eforturi rezonabile pentru a ne asigura ca exista masuri adecvate de protectie a datelor.</div>
                    <div className="mb-3">Mai exact, furnizorul nostru de servicii care ne ajuta sa iti transmitem informari pe e-mail, desi este situat pe teritoriul sua (in afara spatiului ue), detine certificarea privacy shield (scutul de confidentialitate ue-sua) la sectiunea non-hr, asigurand astfel un nivel adecvat de securitate a datelor, recunoscut de comisia europeana.</div>
                    <div className="mb-3">Atunci cand iti transmitem oferte personalizate prin reclame tinta apelam la functia de audiente personalizate gestionata de facebook, inc. Situat in afara ue/spatiului economic european, care insa este certificat conform informatiilor disponibile public conform cerintelor scutului de confidentialitate ue-sua (considerat a asigura un nivel adecvat de protectie a datelor cu caracter personal transferate din uniunea europeana catre organizatii din statele unite). Va recomandam sa consultati si politica de confidentialitate a facebook pe site-ul acestuia.</div>
                    <div className="mb-3">Daca vom transfera datele tale si catre alti parteneri/ furnizori ai platformei situati in state care nu asigura un nivel adecvat de protectie a datelor transmise, ne angajam sa luam toate masurile necesare pentru a ne asigura ca respectivii parteneri/furnizori respecta termenii si conditiile stabilite in aceasta politica. Aceste masuri pot include in plus implementarea unor standarde de protectie a datelor (de ex. Iso 27001), a unor clauze contractuale standard adoptate de comisia uniunii europene, precum si a unor sisteme de control direct al acestor mecanisme.</div>
                    <div className="mb-3 text-semibold">Securitatea datelor</div>
                    <div className="mb-3">Platforma a implementat masurile adecvate de securitate pentru a impiedica ca datele dvs. Cu caracter personal sa fie pierdute, utilizate sau accesate accidental, modificate sau divulgate in mod neautorizat. De asemenea, limitam accesul la datele dvs. Personale acelor angajati, agenti, contractanti si altor terte parti care au o nevoie comerciala de a cunoaste datele respective. Acestia vor procesa datele dvs. Personale la instructiunea noastra si se supun obligatiei de confidentialitate.</div>
                    <div className="mb-3">Am implementat proceduri pentru a trata orice incalcare suspicioasa a datelor cu caracter personal si va vom notifica pe dvs. Si orice autoritate de reglementare competenta cu privire la incalcare, atunci cand suntem obligati legal sa actionam astfel.</div>
                    <div>Putem pastra datele dvs format fizic sau format electronic. In unele circumstante, putem anonimiza datele dvs. Cu caracter personal (astfel incat sa nu mai fie asociate cu dvs.) in scopuri de cercetare sau statistice, caz in care putem utiliza aceste informatii nelimitat fara sa va informam.</div>
                </TermsParagraph>

                <TermsParagraph
                    title="Drepturile tale"
                >
                    <div className="mb-3">In completarea celor mentionate in prezenta politica, in anumite circumstante, persoana vizata are anumite drepturi in conformitate cu legile privind protectia datelor cu privire la datele dvs. Cu caracter personal. Aceste includ:</div>
                    <div className="mb-3 text-semibold">Dreptul de acces</div>
                    <div>Ne puteti cere:</div>
                    <ul>
                        <li> Sa confirmam daca va prelucram datele personale;</li>
                        <li>Sa va dam o copie a acelor date;</li>
                        <li>Sa va oferim alte informatii despre datele dvs. Personale, precum datele pe care le avem, pentru ce le folosim, cui le dezvaluim, daca le transferam in strainatate si cum le protejam, cat timp le pastram, ce drepturi aveti, cum puteti face o plangere, de unde am luat datele dvs. Si daca am efectuat orice proces decizional automatizat sau creare de profiluri, in masura in care informatiile nu v-au fost deja furnizate in aceasta politica.</li>
                    </ul>
                    <div className="mb-3 text-semibold">Dreptul de rectificare</div>
                    <div> Ne puteti cere sa rectificam datele personale inexacte. Este posibil sa cautam sa verificam acuratetea datelor inainte de rectificarea acestora.</div>
                    <ul>
                        <li> Dreptul de stergere (dreptul de a fi uitat)</li>
                        <li>Ne puteti cere sa va stergem datele personale, dar doar in cazul in care:</li>
                        <li>Nu mai sunt necesare pentru scopurile pentru care au fost colectate; sau</li>
                        <li>V-ati retras consimtamantul (cand prelucrarea datelor a fost bazata pe consimtamant); sau</li>
                        <li>Ca urmare a unui drept intemeiat de a obiecta (vedeti „obiectie” mai jos); sau</li>
                        <li>Au fost prelucrate ilegal; sau</li>
                        <li>Trebuie respectata o obligatie legala al carei subiect este compania.</li>
                    </ul>

                    <div>Nu suntem obligati sa raspundem solicitarii dvs. De a va sterge datele personale daca prelucrarea datelor dvs. Personala este necesara:</div>
                    <ul>
                        <li>Pentru respectarea unei obligatii legale; sau</li>
                        <li>Pentru stabilirea, exercitarea sau apararea unor drepturi in justitie;</li>
                        <li>Exista alte cateva circumstante in care nu suntem obligati sa raspundem solicitarii dvs. De stergere, desi acestea doua sunt probabil cele mai comune circumstante in care va vom refuza aceasta solicitare.</li>
                    </ul>
                    <div className="mb-3 text-semibold">Dreptul de restrictie</div>
                    <div> Ne puteti cere sa va restrictionam (si anume, sa le pastram fara a le folosi) datele personale doar cand:</div>
                    <ul>
                        <li>Acuratetea acestora este contestata (vedeti rectificare), pentru a ne permite sa le verificam acuratetea; sau</li>
                        <li>Prelucrarea este ilegala, dar nu doriti sa le stergeti; sau</li>
                        <li>Nu mai sunt necesare pentru scopurile pentru care au fost colectate, dar avem in continuare nevoie de ele pentru a stabili, a exercita sau a apara drepturi in justitie; sau</li>
                        <li>V-ati exercitat dreptul de a obiecta, iar verificarea motivelor imperioase este in asteptare.</li>
                        <li>Putem continua sa folosim datele dvs. Personale in urma unei cereri de restrictie, in cazul in care:</li>
                        <li>Avem consimtamantul dvs.; sau</li>
                        <li>Pentru a stabili, a exercita sau a apara drepturi in justitie; sau</li>
                        <li>Pentru a proteja drepturile unei alte persoane fizice sau juridice.</li>
                    </ul>
                    <div className="mb-3 text-semibold">Dreptul de portabilitate</div>
                    <div>Ne puteti cere sa va furnizam datele dvs. Personale intr-un format structurat, folosit in mod obisnuit, prelucrabil automat, sau puteti solicita sa fie „portate” direct unui alt operator de date, dar in orice caz, doar cand:</div>
                    <ul>
                        <li>Prelucrarea este bazata pe consimtamantul dvs. Sau pe executarea unui contract cu dvs.; si</li>
                        <li>Prelucrarea este realizata prin mijloace automate.</li>
                    </ul>
                    <div className="mb-3 text-semibold">Dreptul de opozitie</div>
                    <div className="mb-3">Puteti obiecta la orice prelucrare a datelor dvs. Personale ce au ca temei juridic „interesele noastre legitime”, daca credeti ca drepturile si libertatile dvs. Fundamentale au prioritate fata de interesele noastre legitime.</div>
                    <div className="mb-3">Odata ce ati obiectat, avem oportunitatea de a va demonstra ca avem interese legitime convingatoare, ce au prioritate fata de drepturile si libertatile dvs.</div>
                    <div className="mb-3 text-semibold">Dreptul de a formula o plangere</div>
                    <div className="mb-3">Aveti dreptul de a depune o plangere la autoritatea nationala de supraveghere a prelucrarii datelor cu caracter personal. Va rugam sa incercati sa rezolvati orice problema discutand, in prima instanta, cu noi, desi aveti dreptul de a contact autoritatea de supraveghere in orice momen</div>
                    <div className="mb-3 text-semibold">Dreptul de a va retrage consimtamantul</div>
                    <div className="mb-3">Aveti dreptul de a va retrage consimtamantul acordat in situatia in care platforma prelucreaza datele cu caracter personal in baza acestuia.</div>
                    <div className="mb-3">Persoana vizata nu va plati un comision sau orice alta taxa pentru a accesa datele dvs. Cu caracter personal (sau de a exercita oricare dintre celelalte drepturi). Totusi, plarforma, in calitate de operator poate impune un onorariu rezonabil daca cererea formulata este vadit nefondata, repetitiva sau excesiva. Alternativ, platforma va putea refuza sa dea curs unei cereri primita in aceste circumstante.</div>
                    <div className="mb-3">Platforma are dreptul de a cere anumite informatii pentru a putea confirma identitatea persoanei vizate care a efectuat solicitarea si a asigura, in siguranta, datele personale (sau de exercita oricare dintre celelalte drepturi). Aceasta este o masura de securitate pentru a asigura ca datele cu caracter personal nu sunt divulgate persoanelor care nu au dreptul sa le primeasca. Va putem contacta pentru a va cere informatii suplimentare cu privire la cererea dvs. De a grabi raspunsul nostru.</div>
                    <div>Platforma va depune diligentele necesare pentru a raspunde la toate cererile legitime in termen de o luna. Ocazional, este posibil sa dureze mai mult de o luna daca cererea persoanei vizate este foarte complexa sau persoana vizata a formulat mai multe cereri. In acest caz, platforma va va notifica si va va tine la curent.</div>
                </TermsParagraph>

                <TermsParagraph
                    title="Sa tinem legatura"
                >
                    <div>Am numit un responsabil cu protectia datelor. Primul punct de contact pentru toate chestiunile ce reies din aceasta politica, inclusiv solicitari de exercitare a drepturilor subiectilor, este departamentul responsabil cu protectie a datelor ce poate fi contactat in urmatoarele moduri:</div>
                    <ul>
                        <li>Prin e-mail la {email}</li>
                        <li>Prin posta la adresa Municipiul Constanta, Strada Rotterdam, Nr. 2, Etaj Parter, Ap. 4, Judet Constanta</li>
                    </ul>

                    <div className="mb-3">Daca aveti o plangere sau sunteti ingrijorat in legatura cu modul in care va folosim datele personale, va rugam sa ne contactati, in prima instanta, pe noi si noi vom incerca sa rezolvam problema cat mai curand posibil.</div>
                    <div>Va dorim navigare placuta!</div>
                </TermsParagraph>

                <TermsParagraph
                    title="Politica privind cookies"
                >
                    <div className="mb-3">Informațiile prezentate în continuare au scopul de a aduce la cunoștința vizitatorului/utilizatorului mai multe detalii despre plasarea, utilizarea si administrarea “cookie”-urilor utilizate de site-ul autocar.Ro. De asemenea, sunt prezente si cateva link-uri utile legate de acest subiect.</div>
                    <div>Acest website foloseste cookie-uri pentru a furniza vizitatorilor o experiență mult mai bună de navigare șI servicii adaptate nevoilor șI interesului fiecăruia.</div>
                </TermsParagraph>

                <TermsParagraph
                    title="Rolul unui cookie"
                >
                    <div>În ceea ce numim “web 2.0”, “cookie”-urile joacă un rol important în facilitarea accesului șI livrării multiplelor servicii de care utilizatorul se bucură pe internet, cum ar fi:</div>
                    <ul>
                        <li>personalizarea anumitor setări precum: limba în care este vizualizat un site, moneda în care se exprimă anumite prețuri sau tarife, păstrarea opțiunilor pentru diverse produse (măsuri, alte detalii etc), autentificarea utilizatorilor fața de server (cu ajutorul cookie-urilor serverul reține faptul că utilizatorul s-a autentificat șI îI va permite acțiuni specifice celor autentificațI), setări pe care utilizatorul le-a făcut în mod specific.</li>
                        <li>cookie-urile oferă deținătorilor de site-uri un feedback valoros asupra modului cum sunt utilizate site-urile lor de către utilizatori, astfel încât să le poată face șI mai eficiente șI mai accesibile pentru utilizatori.</li>
                        <li>cookie-urile permit aplicațiilor multimedia sau de alt tip de pe alte site-uri să fie incluse într-un anumit site pentru a crea o experiență de navigare mai valoroasă, mai utilă șI mai placută.</li>
                        <li>îmbunătățesc eficiența publicității online.</li>
                    </ul>
                </TermsParagraph>

                <TermsParagraph
                    title="Ce este un cookie?"
                >
                    <div className="mb-3">Un “internet cookie” (termen cunoscut șI ca “browser cookie” sau “http cookie” sau pur si simplu “cookie”) este un fișier de mici dimensiuni, format din litere șI numere, care va fi stocat pe computerul, terminalul mobil sau alte echipamente ale unui utilizator de pe care se accesează internetul.</div>
                    <div className="mb-3">Cookie-ul este instalat prin solicitarea emisă de către un web-server unui browser ( ex: internet explorer, google chrome) si este complet “pasiv” (nu conține programe software, virusi sau spyware si nu poate accesa informațiile de pe hard drive-ul utilizatorului).</div>
                    <div className="mb-3">Cookie-urile sunt folosite de aproape toate site-urile web șI nu dăunează sistemului dvs.</div>
                    <div className="mb-3 text-semibold">Componența unui cookie</div>
                    <div className="mb-3">Un cookie este format din 2 părțI: numele șI conținutul sau valoarea cookie-ului. Mai mult, durata de existență a unui cookie este determinată; tehnic, doar web-server-ul care a trimis cookie-ul îl poate accesa din nou în momentul în care un utilizator se întoarce pe website-ul asociat web-server-ului respectiv.</div>
                    <div className="mb-3">Cookie-urile în sine nu solicită informații cu caracter personal pentru a putea fi utilizate șI, în cele mai multe cazuri, nu identifică personal utilizatorii de internet.</div>
                    <div className="mb-3 text-semibold">Categoriile de cookie-uri</div>
                    <div>Există 2 mari categorii de cookie-uri:</div>
                    <ul>
                        <li>Cookie-uri de sesiune – acestea sunt stocate temporar în dosarul de cookie-uri al browserului web pentru ca acesta să le memoreze până când utilizatorul iese de pe web-site-ul respectiv sau închide fereastra browserului ( ex: în momentul logarii/delogarii (de) pe un cont de web mail sau pe rețele de socializare).</li>
                        <li>Cookieuri persistente – acestea sunt stocate pe hard-drive-ul unui computer sau echipament (șI în general depinde de durata de viață prestabilită pentru cookie). Cookie-urile persistente le includ si pe cele plasate de un alt web-site decât cel pe care îl vizitează utilizatorul la momentul respectiv – cunoscute sub numele de ”third party cookies” (cookieuri plasate de terțI iar nu de proprietarul website-ului respectiv) – care pot fi folosite în mod anonim pentru a memora interesele unui utilizator, astfel încât să fie livrată publicitate cât mai relevantă pentru utilizatori. Furnizorii terțI trebuie să respecte de asemenea legea în vigoare șI politicile de confidențialitate ale deținătorului site-ului.</li>
                    </ul>
                    <div className="mb-3">ReținețI că terții care fac publicitate pe site-ul nostru (inclusiv, de exemplu, rețelele de publicitate șI furnizorii de servicii externe, cum ar fi serviciile de analiză a traficului web) pot utiliza, de asemenea, module cookie, asupra cărora nu avem control. Este posibil ca aceste module cookie să fie cookie-uri de analiză / performanță sau cookie-uri de direcționare.</div>
                    <div>De asemenea, cookie-urile mai pot fi clasificate șI după cum urmează:</div>
                    <ul>
                        <li>Cookie-uri strict necesare - aceste cookie-uri sunt esențiale pentru a vă permite să utilizațI site-ul în mod eficient, cum ar fi atunci când cumpărațI un produs șI / sau serviciu șI, prin urmare, nu poate fi oprit. Fără aceste cookie-uri, serviciile disponibile pe site-ul nostru nu pot fi furnizate. Aceste cookie-uri nu adună informații despre dvs. Care ar putea fi utilizate pentru marketing sau amintirea locului în care ațI fost pe internet.</li>
                        <li>Cookie-uri de preferinte - cookie-urile de preferinţă permit unui site să îşI amintească informaţii care se modifică după modul în care se comportă sau arată site-ul, precum limba dvs. Preferată sau regiunea în care vă aflaţI.</li>
                        <li>Cookie-uri de performanță si statistici - aceste module cookie permit site-ului nostru să înţeleagă modul în care vizitatorii interacţionează cu site-urile prin colectarea şI raportarea informaţiilor în mod anonim, ne permit să monitorizăm șI să îmbunătățim performanța site-ului nostru.</li>
                        <li>Cookie-uri de marketing - aceste module cookie sunt utilizate pentru a-I urmări pe utilizatori de la un site la altul si a afişa anunţuri relevante şI antrenante pentru utilizatorii individuali.</li>
                    </ul>
                    <div>Cookie-uri folosite:</div>
                    <ul>
                        <li>Categorie: necesare(4) <br /> Cookie-urile necesare ajuta la crearea unui site web usor de utilizat, permitand functii de baza cum ar fii navigarea intre pagini si accesul in siguranta catre anumite zone ale site-ului web. Site-ul web nu poate functiona corect fara aceste cookie-uri. </li>
                        <li>Categorie: statistice(9) <br /> Cookie-urile din categoria statistice, ajuta administratorii site-ului web sa inteleaga cum interactioneaza utilizatorii cu acest site, prin colectarea si raportarea anonima a informatiilor.</li>
                        <li>Categorie: marketing (27) <br /> Cookie-urile din categoria marketing sunt folosite pentru a urmari site-urile vizitate de utilizator. Scopul acestora este de a afisa reclame care sunt relevante si care angajeaza utilizatorul, asadar sunt mult mai valoroase pentru cei care posteaza reclame sau pentru terti care posteaza reclame.</li>
                    </ul>
                    <div className="mb-3 text-semibold">Schimbare preferinte cookie</div>
                    <div className="mb-3">De ce sunt cookie-urile importante pentru internet?</div>
                    <div className="mb-3">Cookie-urile reprezintă punctul central al funcționării eficiente a internetului, ajutând la generarea unei experiențe de navigare prietenoase șI adaptată preferințelor șI intereselor fiecărui utilizator. Refuzarea sau dezactivarea cookieurilor poate face unele site-uri imposibil de folosit.</div>
                    <div className="mb-3">Refuzarea sau dezactivarea cookie-urilor nu înseamna că nu vețI mai primi publicitate online – ci doar că aceasta nu va mai putea ține cont de preferințele șI interesele dvs., evidențiate prin comportamentul de navigare.</div>
                    <div className="mb-3 text-semibold">Securitate șI probleme legate de confidențialitate</div>
                    <div className="mb-3">Cookieurile nu sunt viruș!</div>
                    <div className="mb-3">Ele folosesc formate tip plain text. Nu sunt alcătuite din bucățI de cod așa că nu pot fi executate nici nu pot autorula. În consecință, nu se pot duplica sau replica pe alte rețele pentru a se rula sau replica din nou. Deoarece nu pot îndeplini aceste funcții, nu pot fi considerate virușI.</div>
                    <div className="mb-3">Cookie-urile pot fi totușI folosite pentru scopuri negative. Deoarece stochează informații despre preferințele șI istoricul de navigare al utilizatorilor, atât pe un anume site cât șI pe mai multe alte site-uri, cookie-urile pot fi folosite ca o formă de spyware. Multe produse anti-spyware sunt conștiente de acest fapt șI în mod constant marchează cookie-urile pentru a fi șterse în cadrul procedurilor de ștergere/scanare anti-virus/anti-spyware.</div>
                    <div>În general, browser-ele au integrate setări de confidențialitate care furnizează diferite nivele de acceptare a cookie-urilor, perioada de valabilitate șI ștergere automată după ce utilizatorul a vizitat un anumit site.</div>
                </TermsParagraph>

                <TermsParagraph
                    title="Alte aspecte legate de securitatea cookie-urilor"
                >
                    <div className="mb-3">În completarea celor ante-menționate, datorită faptului că protecția identității este foarte importantă șI reprezintă dreptul fiecărui utilizator de internet, este indicat să se cunoască ce eventuale probleme pot crea cookie-urile.</div>
                    <div className="mb-3">Datorită faptului că prin intermediul lor se transmit, în mod constant, în ambele sensuri informații între browser si website, dacă un atacator sau persoană neautorizată intervine în parcursul de transmitere a datelor, informațiile conținute de cookie pot fi interceptate. DeșI foarte rar, acest lucru se poate întâmpla dacă browserul se conectează la server folosind o rețea necriptată (ex: o rețea wifi nesecurizată).</div>
                    <div className="mb-3">Alte atacuri bazate pe cookie implică setări greșite ale cookieurilor pe servere. Daca un website nu solicită browser-ului să folosească doar canale criptate, atacatorii pot folosi aceasta vulnerabilitate pentru a ”păcăli” browser-ele în a trimite informații prin intermediul canalelor nesecurizate. Atacatorii utilizează apoi informațiile în scopuri de a accesa, neautorizat, anumite site-uri. Este foarte important sa fițI atențI în alegerea metodei celei mai potrivite de protecție a informațiilor personale.</div>
                    <div className="mb-3">Îndrumări, ca urmare a utilizării de cookies, pentru a asigura o navigare în siguranță șI în cunoștință de cauză</div>
                    <div className="mb-3">Datorita flexibilității cookie-urilor cât șI a faptului că majoritatea dintre cele mai vizitate site-uri folosesc cookieuri, acestea sunt aproape inevitabile. Dezactivarea cookie-urilor nu va permite accesul utilizatorului pe site-urile cele mai răspândite șI utilizate, printre care se numără youtube, gmail, yahoo șI altele.</div>
                    <div>Iată câteva îndrumări (pe care le putețI ignora dacă nu le considerațI utile pentru dvs.) care vă pot asigura că navigațI fără griji, însă folosind cookie-uri:</div>
                    <ul>
                        <li>particularizațI-vă setările browserului, în ceea ce privește cookie-urile, pentru a reflecta un nivel confortabil pentru voi al securitatii utilizării cookie-urilor;</li>
                        <li>dacă nu vă deranjează cookie-urile șI suntețI singura persoană care utilizează computerul, putețI seta termene lungi de expirare pentru stocarea istoricului de navigare șI al datelor personale de acces;</li>
                        <li>dacă impărțIțI accesul la calculator, putețI lua în considerare setarea browserului pentru a șterge datele individuale de navigare de fiecare dată când închidețI browserul. Aceasta este o variantă de a accesa site-urile care plasează cookie-uri șI de a șterge orice informație de vizitare la închiderea sesiunii navigare;</li>
                        <li>instalațI-vă si updatati-vă constant aplicații antispyware. Multe dintre aplicațiile de detectare șI prevenire a spyware-ului includ detectarea atacurilor pe site-uri;</li>
                        <li>asigurati-vă că avețI browserul mereu updatat. Multe dintre atacurile bazate pe cookies se realizează exploatând punctele slabe ale versiunilor vechi ale browserelor.</li>
                        <li></li>
                    </ul>
                    <div className="mb-3 text-semibold">Posibilitatea șI modul de a opri (a stopa) ”cookie”-urile</div>
                    <div className="mb-3">În prealabil, vă avertizăm că dezactivarea șI refuzul de a primi cookie-uri pot face anumite site-uri impracticabile sau dificil de vizitat șI folosit. De asemenea, refuzul de a accepta cookie-uri nu înseamna că nu vețI mai primi/vedea publicitate online.</div>
                    <div className="mb-3">Este posibilă setarea din browser pentru ca aceste cookie-uri sa nu mai fie acceptate sau putețI seta browserul să accepte cookie-uri de la un site anume. Dar, de exemplu, dacă nu suntețI înregistat folosind cookie-urile, nu vei putea lăsa comentarii.</div>
                    <div className="mb-3">Toate browserele moderne oferă posibilitatea de a schimba setările cookie-urilor. Aceste setări se găsesc, de regulă, în “opțiuni” sau în meniul de “preferințe” al browserului tău.</div>
                    <div>Pentru a înțelege aceste setări, următoarele link-uri pot fi folositoare sau putețI folosi opțiunea “ajutor/ help” a browserului pentru mai multe detalii.</div>
                    <ul>
                        <li><a href="http://support.Microsoft.Com/kb/196955" target="_blank">Cookie settings in internet explorer</a></li>
                        <li><a href="http://support.Mozilla.Org/en-us/kb/cookies-information-websites-store-on-your-computer?Redirectlocale=en-us&redirectslug=cookies " target="_blank">Cookie settings in firefox </a></li>
                        <li><a href="https://support.google.com/chrome/answer/95647?hl=ro&hlrm=ro" target="_blank">Cookie settings in chrome</a></li>
                        <li><a href="http://support.Apple.Com/kb/ph5042" target="_blank">Cookie settings in safari</a></li>
                        <li><a href="https://www.youronlinechoices.com/ro/" target="_blank">Pentru setările cookie-urilor generate de terțI, putețI consulta și acest site</a></li>
                    </ul>
                    <div className="mb-3 text-semibold">Linkuri utile</div>
                    <div>Dacă dorițI să aflațI mai multe informații despre cookie-uri șI la ce sunt utilizate, recomandăm următoarele link-uri:</div>
                    <ul>
                        <li><a href="http://www.microsoft.com/info/cookies.Mspx" target="_blank">Microsoft cookies guide</a></li>
                        <li><a href="http://www.Allaboutcookies.Org" target="_blank">All about cookies</a></li>
                        <li><a href="https://www.youronlinechoices.com/ro/" target="_blank">Asociația iab romania pune la dispoziție următorul site pentru a oferi mai multe informatii privind confidențialitatea legată de publicitatea online</a></li>
                    </ul>
                    <div className="mt-5 text-semibold">Va uram navigare placuta si sigura!</div>
                </TermsParagraph>
            </div>
        </div>
    );
}
