import React, { useEffect, useState } from "react";
import { Routes, Route } from 'react-router-dom';

import { Layout } from './components/shared/layout';
import { Home } from './components/pages/home';
import { Gallery } from './components/pages/gallery';
import { Apartments } from './components/pages/apartments';
import { ApartmentsTypes } from './components/pages/apartmentsTypes';
import { ApartmentDetails } from './components/pages/apartmentDetails';
import { Contact } from './components/pages/contact';
import { CookiePolicy } from './components/pages/cookiePolicy';
import { TermsAndConditions } from './components/pages/termsAndConditions';
import { PrivacyPolicy } from './components/pages/privacyPolicy';
import { NavigatePage } from './components/pages/navigatePage';

const App = () => {
   //  <Route exact path='/n/:data?' element={<NavigatePage />} />
    return (
        <Layout>
            <Routes>

                <Route exact path='/apartamente' element={<Apartments />} />

                <Route exact path='/tipurideapartamente' element={<ApartmentsTypes />} />

                <Route exact path='/apartament' element={<ApartmentDetails />} />

                <Route exact path='/galerie' element={<Gallery />} />

                <Route exact path='/contact' element={<Contact />} />

                <Route exact path='/cookie' element={<CookiePolicy />} />

                <Route exact path='/termeniconditii' element={<TermsAndConditions />} />

                <Route exact path='/confidentialitate' element={<PrivacyPolicy />} />
                
                <Route path='*' element={<Home />} />

            </Routes>
        </Layout>
    );
}

export default App;
