import React, { useEffect, useState, useMemo } from "react";
import { Section, FullPageImage, ApartmentPageImage } from '../shared/customHtml';
import { useLocation, useNavigate } from "react-router-dom";
import { generalService } from "../services/general.service";
import { Loading } from '../shared/loading';

export const useQueryString = () => {
    const { search } = useLocation();

    return useMemo(() => {
        let searchParams = new URLSearchParams(search);
        let result = {};

        //Iterate the search parameters.
        for (let p of searchParams) {
            result[p[0]] = p[1];
        }

        return result;

    }, [search]);
}

export const ApartmentsTypes = () => {
    const queryString = useQueryString();
    const navigate = useNavigate();

    const [info, setInfo] = useState();
    const [dataSource, setDataSource] = useState([]);
    const [hasLoading, setHasLoading] = useState(true);

    useEffect(() => {
        let _data = generalService.getDecodedDataBase64(queryString.q);
        if (!_data || !_data.type || !_data.introImage) {
            navigate('/apartamente');
        }
        setInfo(_data);
        handleGetData();
    }, []);

    const handleGetData = () => {
        generalService.getApartments().then(response => {
            if (response) {
                setDataSource(response);
            }
            setHasLoading(false);
        });
    }

    if (!info) {
        return null;
    }

    const getFieldProps = () => {
        switch (info?.type?.toLowerCase()) {
            case 'tworooms':
                return {
                    title: 'Apartamente cu 2 camere',
                    dataSource: dataSource.filter(x => x.type?.toLowerCase() == "2camere") ?? []
                };
            case 'duplexes':
                return {
                    title: 'Apartamente duplex',
                    dataSource: dataSource.filter(x => x.type?.toLowerCase() == "duplex") ?? []
                };
            case 'threerooms':
                return {
                    title: 'Apartamente cu 3 camere',
                    dataSource: dataSource.filter(x => x.type?.toLowerCase() == "3camere") ?? []
                };

            default:
                return {
                    title: 'Apartamente studio',
                    dataSource: dataSource.filter(x => x.type?.toLowerCase() == "studio") ?? []
                };
        }
    }

    const fieldProps = getFieldProps();

    const items = fieldProps.dataSource.map((item, index) => {
        return (<ApartmentItem key={`${index}`} item={item} info={info} />);
    });

    return (
        <div>
            <Section>
                <FullPageImage
                    src={info.introImage}
                    overlay={true}
                    title={fieldProps.title}
                />
            </Section>

            {hasLoading && <Loading />}

            {items?.length > 0 && <div className="apartments-types-container">
                <div className="row m-0 p-0">
                    {items}
                </div>                
            </div>}
        </div>
    );
}


const ApartmentItem = ({ item, info }) => {
    const navigate = useNavigate();

    const goToDetails = () => {
        if (item.soldOut) {
            return;
        }

        let data = generalService.encodeUrlBase64(JSON.stringify({
            item: item,
            info: info
        }));

        navigate('/apartament?q=' + data);
    }

    const title = <>
        {!item.soldOut && <div><img className="view-button" src="/images/arrow-button-white.svg" alt="..." /></div>}
        {item.number && <div>Ap. {item.number}</div>}
        {item.floor && <div>Et. {item.floor}</div>}
    </>;

    return (<div className="col col-lg-4 col-md-4 col-sm-6 col-12" onClick={goToDetails}>
        <ApartmentPageImage
            src={item.previewPlanImage}
            keepShadow={true}
            noButtonRight={true}
            title={title}
            soldOut={item.soldOut}
            titleClassName={"apartments-types-title"}
            previewPlanImage={true}
        />
    </div>);
}