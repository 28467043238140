import { read, utils } from "xlsx";

export const generalService = {
    getScrollTop,
    setScrollTop,
    parsePhoneNumber,
    formatPhoneNumber,
    formatPlusPhoneNumber,
    getWindowWidth,
    isMinDesktop,
    isDesktop,
    isDesktop4k,
    isTablet,
    isMobile,
    downloadBrochure,
    goToGoogleMaps,
    encodeUrlBase64,
    decodeUrlBase64,
    getDecodedDataBase64,
    getWindowSize,
    getApartments
};
function getScrollTop() {
    return document.body.scrollTop || document.documentElement.scrollTop;
};
function setScrollTop(scrollHeight) {
    document.body.scrollTop = scrollHeight;
    document.documentElement.scrollTop = scrollHeight;
};

function parsePhoneNumber(phoneNumber) {
    if (!phoneNumber) return '';

    return phoneNumber.replace(/\D/g, '');
}

function formatPhoneNumber(phoneNumber) {
    let numericOnly = parsePhoneNumber(phoneNumber);

    if (numericOnly.length !== 10) {
        return numericOnly;
    }

    return numericOnly.replace(/(\d{4})(\d{3})(\d{3})/, '$1 $2 $3');
}

function formatPlusPhoneNumber(phoneNumber) {
    let numericOnly = parsePhoneNumber(phoneNumber);

    if (numericOnly.length !== 10) {
        return numericOnly;
    }

    return numericOnly.replace(/(\d{1})(\d{3})(\d{3})(\d{3})/, '+4$1 $2 $3 $4');
}

function getWindowWidth() {
    return Math.max(
        document.body.clientWidth ? document.body.clientWidth : 0,
        document.documentElement.clientWidth ? document.documentElement.clientWidth : 0,
        window.innerWidth ? window.innerWidth : 0
    );
}

function isMinDesktop() {
    let width = getWindowWidth();
    return width >= 1024;
}

function isDesktop() {
    let width = getWindowWidth();
    return width >= 1024 && width < 1440;
}

function isDesktop4k() {
    return getWindowWidth() >= 1440;
}

function isTablet() {
    let width = getWindowWidth();
    return width >= 768 && width < 1024;
}

function isMobile() {
    return getWindowWidth() < 768;
}

function downloadBrochure() {
    const brochure = window.datasets?.links?.brochure;
    window.open(brochure, '_blank');
}
function goToGoogleMaps() {
    const googlemaps = window.datasets?.links?.googlemaps;
    window.open(googlemaps, '_blank');
}

function encodeUrlBase64(s) {
    return window.encodeURIComponent(window.btoa(window.encodeURIComponent(s)));
}

function decodeUrlBase64(s) {
    return window.decodeURIComponent(window.atob(window.decodeURIComponent(s)));
}

function getDecodedDataBase64(data) {
    let result = null;
    try { if (data) { result = JSON.parse(decodeUrlBase64(data)); } }
    catch (ex) { result = null }
    return result;
}

function getWindowSize () {
    var docEl = document.documentElement,
        IS_BODY_ACTING_ROOT = docEl && docEl.clientHeight === 0;

    // Used to feature test Opera returning wrong values 
    // for documentElement.clientHeight. 
    function isDocumentElementHeightOff() {
        var d = document,
            div = d.createElement('div');
        div.style.height = "2500px";
        d.body.insertBefore(div, d.body.firstChild);
        var r = d.documentElement.clientHeight > 2400;
        d.body.removeChild(div);
        return r;
    }

    if (typeof document.clientWidth == "number") {
        return { width: document.clientWidth, height: document.clientHeight };
    }

    if (IS_BODY_ACTING_ROOT || isDocumentElementHeightOff()) {
        var b = document.body;
        return { width: b.clientWidth, height: b.clientHeight };
    } 

    return { width: docEl.clientWidth, height: docEl.clientHeight };
}

function getApartments() {
    return fetch('/uploads/apartments.xlsx?q=' + Math.random())
        .then(response => response.arrayBuffer())
        .then(bytes => {
            const wb = read(bytes);
            const options = {
                header: [
                    'number',
                    'floor',
                    'rooms',
                    'totalSurface',
                    'usefulSurface',
                    'balconySurface',
                    'type',
                    'soldOut',
                    'planImage'
                ]
            };

            const dsStudio = readRow(wb, 'studio');
            const ds2camere = readRow(wb, '2camere');
            const dsDuplex = readRow(wb, 'duplex');
            const ds3camere = readRow(wb, '3camere');

            return dsStudio.concat(ds2camere).concat(dsDuplex).concat(ds3camere);
        });
}

function readRow(wb, apType) {
    const sheetName = wb.SheetNames.find(x => (x?.replace(/\s/g, '') ?? '').toLowerCase() == apType);

    if (!sheetName) {
        return [];
    }

    const options = {
        header: [
            'number',
            'floor',
            'totalSurface',
            'usefulSurface',
            'balconySurface',
            'soldOut'
        ]
    };

    const data = utils.sheet_to_json(wb.Sheets[sheetName], options);

    if (data) {
        let _dataSource = data.slice(1);
        let _apFolder = apType == 'studio' ? 'STUDIO' :
            apType == '2camere' ? '2 CAMERE' :
                apType == '3camere' ? '3 CAMERE' : null;

        _dataSource = _dataSource.filter(x => x.number != undefined && x.number != null);

        _dataSource.forEach(x => {
            x.type = apType;
            x.soldOut = x.soldOut || x.soldOut == 1 || x.soldOut == '1';
            x.rooms = apType == 'studio' ? 1 :
                apType == '2camere' ? 2 :
                    apType == '3camere' ? 3 : null;

            let apImg = x.number + '';
            if (apImg.length == 1) { apImg = '0' + apImg; }
            x.number = apImg;
            x.planImage = `/uploads/PLANURI PDF/PLAN MARE/${_apFolder}/Ap. ${apImg}.png`;
            x.previewPlanImage = `/uploads/PLANURI PDF/PREVIEW/${_apFolder}/Ap. ${apImg}.png`;
        });

        return _dataSource;
    }

    return [];
}