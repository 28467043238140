import React, { useState, useEffect, useRef } from "react";
import { validatorService } from '../services/validator.service';
import { generalService } from '../services/general.service';
import Carousel from 'react-bootstrap/Carousel';
import $ from 'jquery';
import { Loading } from './loading';

export const SectionOld = (props) => {
    let style = {};
    const [height, setHeight] = useState();

    useEffect(() => {
        if (generalService.isMobile()) {
            var _height = generalService.getWindowSize()?.height ?? parseInt(window.innerHeight);
            setHeight(_height + 'px');
        }
    }, []);

    if (height) {
        style.height = height;
    }

    return (<div className={"section"} style={style}>
        {props.children}
    </div>);
}

export const Section = (props) => {
    let style = {};  
    const divRef = useRef();
    const [height, setHeight] = useState();

    useEffect(() => {
        if (generalService.isMobile()) {
            var _height = generalService.getWindowSize()?.height ?? parseInt(window.innerHeight);
            setHeight(_height + 'px');
        }
        if (generalService.isMinDesktop()) {
            window.addEventListener('scroll', handleScroll);
            return () => {
                window.removeEventListener('scroll', handleScroll);
            };

        }
    }, []);

    const handleScroll = () => {
        if (divRef.current) {
            let rect = divRef.current.getBoundingClientRect();

            var _height = generalService.getWindowSize()?.height ?? parseInt(window.innerHeight);

            if (rect && rect.bottom >= _height) {
                divRef.current.classList.add('section-fixed');
            } else {
                divRef.current.classList.remove('section-fixed');
            }
        }
    }

    if (height) {
        style.height = height;
    }

    return (<div className={"section section-fixed"} style={style} ref={divRef} >
        {props.children}
    </div>);
}

export const Paragraph = (props) => {
    return (<div className={"paragraph " + (props.className ?? '')}>
        {props.children}
    </div>);
}

export const TermsParagraph = (props) => {
    return (<div className="mt-5 mb-5">
        <div className="paragraph-title mb-2">{props.title}</div>
        <div className="paragraph-text"> {props.children}</div>
    </div>);
}

export const FullPageImage = ({ src, overlay, title, titleClassName, onClick, shadow, soldOut, home }) => {
    /*const [hasLoading, setHasLoading] = useState(true);

    const handleStopLoading = () => {
        setHasLoading(false);
    }
    onLoad={handleStopLoading} onError={handleStopLoading} 
     {hasLoading && <Loading />}
    */

    return (<>
        <div className={"full-page-image-container"} onClick={onClick}>
            {overlay && <img src={home ? "/images/overlay-home.svg" : "/images/overlay.svg"} className="overlay-image" alt="..." />}
            {soldOut && <><img src="/images/sold-out-overlay.svg" className="overlay-image sold-out-image" alt="sold out" /> <div className="sold-out-text"><span className="image-title">Sold out</span></div> </>}
            {shadow && <div className="full-page-shadow"></div>}
            <img src={src} className="full-page-image" alt="page image" />
            {title && <span className={"image-title align-l " + (titleClassName ?? '')}>{title}</span>}
        </div>
    </>);
}

export const ApartmentPageImage = ({ src, overlay, title, titleClassName, onClick, soldOut, home, keepShadow, noButtonRight, previewPlanImage }) => {
    const [hover, setHover] = useState(false);

    const handleClick = () => {
        if (soldOut) {
            if (hover) return;

            setHover(true);
            setTimeout(function () { setHover(false); }, 2000);
        }
        else if (onClick) {
            onClick();
        }
    }

    const handleMouseEnter = () => {
        setHover(true);
    }

    const handleMouseLeave = () => {
        setHover(false);
    }

    const _className = previewPlanImage ? "full-page-image-container preview-plan-image" : "full-page-image-container";
    return (<div className={_className} onClick={handleClick} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {overlay && <img src={home ? "/images/overlay-home.svg" : "/images/overlay.svg"} className="overlay-image" alt="..." />}
        <img src={src} className="full-page-image" alt="page image" />
        {(!hover || keepShadow) && <div className="full-page-shadow"></div>}
        {title && <span className={"image-title align-l " + (titleClassName ?? '') + (hover ? ' active' : '')}>
            {title}
        </span>}

        <div className={hover ? "transition-container active" : "transition-container"}>
            {soldOut && <><img src="/images/sold-out-overlay.svg" className="overlay-image sold-out-image" alt="sold out" /> <div className="sold-out-text"><span className="image-title">Sold out</span></div></>}
            {!soldOut && !noButtonRight && <><img className="view-button-right align-r" src="/images/arrow-button-white.svg" alt="..." /></>}
        </div>
       
        
       
    </div>);
}

export const CarouselImages = ({ src, controls, indicators }) => {
    const data = src.map((x, index) => {
        return (<Carousel.Item key={`${index}`}>
            <img className="d-block w-100" src={x} alt="..." />
        </Carousel.Item>);
    })
    const indicatorLabels = src.map((x, index) => ("0" + (index + 1)).slice(-2));

    return (<Carousel className="carousel-fade"
        controls={controls}
        indicators={indicators}
        pause={false}
        touch={true}
        interval={2000}
        indicatorLabels={indicatorLabels}
    >
        {data}
    </Carousel>);
}
export const ElementIcon = ({ rows, className }) => {
    var cells = generalService.isTablet() ? 2 :
        generalService.isDesktop() ? 4 :
            generalService.isDesktop4k() ? 6 : 0;
    if (cells == 0) {
        return;
    }

    const size = (rows ?? 0) * cells;
    const data = [...Array(size)].map((x, index) => {
        return (<img key={`${index}`} src="/images/element-icon.svg" alt="Icon" />);
    })

    return (<div className={"element-icon " + (className ?? '')}>
        {data}
    </div>);
}

export const InputField = (props) => {
    const { label, name, className, type, placeholder, formValues, validations, ...others } = props;
    const [value, setValue] = useState('');
    const [error, setError] = useState('');
    const inputRef = useRef();

    useEffect(() => {
        if (formValues?.current) {
            if (formValues.current[name] != value) {
                setValue(formValues.current[name] ?? '');
                setError('');
            }
        }
    }, [formValues?.current]);

    useEffect(() => {
        if (inputRef?.current) {
            if (value) {
                inputRef?.current.classList.add('focus');
            }
            else {
                inputRef?.current.classList.remove('focus');
            }
        }
    }, [value]);

    let _className = "form-floating form-field " + (error ? ' invalid ' : '') + (className ?? '');
    let _type = type ?? 'text';
    let _placeholder = placeholder ?? name ?? label;

    const onChange = (event) => {
        if (!formValues || !event?.target) {
            return;
        }
        if (!formValues.current) {
            formValues.current = {};
        }

        formValues.current[name] = event.target.value;
        setValue(event.target.value);
    }

    const onBlur = (event) => {
        if (!validations || !formValues || !event?.target) {
            return;
        }

        if (!formValues.current) {
            formValues.current = {};
        }

        if (!formValues.current.errors) {
            formValues.current.errors = [];
        }

        let message = validatorService.validateField({
            validations: validations,
            value: event.target.value,
            label: label
        });

        if (message) {
            let itemFound = formValues.current.errors.find(x => x.name == name);
            if (itemFound) {
                itemFound.message = message;
            }
            else {
                formValues.current.errors.push({
                    name,
                    message
                });
            }
        }
        else {
            formValues.current.errors = formValues.current.errors.filter(x => x.name != name);
        }

        setError(message);
    }

    return (<>
        <div className={_className}>
            <input {...others} ref={inputRef} className="form-control" id={`input_${name}`} value={value} type={_type} placeholder={_placeholder} onChange={onChange} onBlur={onBlur} autoComplete={"off"} />
            <label htmlFor={`input_${name}`}>{label}</label>
        </div>
    </>);


    return (<>
        <div className={_className}>
            <input {...others} className="form-control" id={`input_${name}`} value={value} type={_type} placeholder={_placeholder} onChange={onChange} onBlur={onBlur} autoComplete={"off"} />
            <label htmlFor={`input_${name}`}>{label}</label>
        </div>        
    </>);
}

export const CheckboxField = (props) => {
    const { label, name, className, formValues, validations, ...others } = props;
    const [value, setValue] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        if (formValues?.current) {
            if (formValues.current[name] != value) {
                setValue(!!formValues.current[name]);
            }
        }
    }, [formValues?.current]);

    let _className = "form-check" + (error ? ' invalid ' : '') + (className ?? '');

    const onChange = (event) => {
        if (!formValues || !event?.target) {
            return;
        }
        if (!formValues.current) {
            formValues.current = {};
        }

        formValues.current[name] = event.target.checked;
        setValue(event.target.checked);

        onBlur(event);
    }

    const onBlur = (event) => {
        if (!validations || !formValues || !event?.target) {
            return;
        }

        if (!formValues.current) {
            formValues.current = {};
        }

        if (!formValues.current.errors) {
            formValues.current.errors = [];
        }

        let message = validatorService.validateField({
            validations: validations,
            value: event.target.checked,
            label: label
        });

        if (message) {
            let itemFound = formValues.current.errors.find(x => x.name == name);
            if (itemFound) {
                itemFound.message = message;
            }
            else {
                formValues.current.errors.push({
                    name,
                    message
                });
            }            
        }
        else {
            formValues.current.errors = formValues.current.errors.filter(x => x.name != name);
        }

        setError(message);
    }

    return (<>
        <div className={_className}>
            <input {...others} className="form-check-input" type="checkbox" id={`input_${name}`} checked={value} onChange={onChange} onBlur={onBlur} />
            {label && <label className="form-check-label" htmlFor={`input_${name}`}>{label}</label>}
        </div>
    </>);
}

export const ErrorField = ({ text }) => {
    return (<>
        <span className="error-field">
            <img src="/images/error-icon.svg" className="" alt="Eroare" />
            <span>{text}</span>
        </span>
    </>);
}