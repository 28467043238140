import React, { useEffect, useRef, useState, useReducer } from "react";
import { Menu } from './menu';
import { Footer } from './footer';
import { Cookies } from './cookies';
import { useLocation } from "react-router-dom";
import { Loading } from '../shared/loading';
import $ from 'jquery';
import { generalService } from '../services/general.service';

export const Layout = (props) => {
    const location = useLocation();
    const pageLoaded = useRef(false);
    const [loading, setLoading] = useState(true);
    const prevScroll = useRef(0);
    const scrollRef = useRef(0);
    const scrollToContact = useRef(false);
    const timeoutId = useRef();

    useEffect(() => {
        handleScroll();

        if ('scrollRestoration' in history) {
            history.scrollRestoration = 'manual';
        }

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('popstate', handlePopState);
        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('popstate', handlePopState);
        };

    }, []);

    useEffect(() => {
        handleInit();
    }, [location]);

    const handleInit = (e) => {
        $('body').removeClass('scroll-down-body');
        $('body').removeClass('fixed-body');

        pageLoaded.current = false;
        setLoading(true);
        handleStartTimeOut();
    }

    const handleStopLoading = (e) => {
        timeoutId.current = null;
        pageLoaded.current = true;
        setLoading(false);

        let pathname = window.location?.pathname?.toLowerCase() ?? '';

        if (pathname == '/contact') {
            scrollToContact.current = true;
            handleScrollToContact();
        }
    }

    const getScroll = (elementId) => {
        const element = document.getElementById(elementId);
        if (!element) {
            return 0;
        }

        const elementTop = element.getBoundingClientRect()?.top ?? 0;
        const winScroll = generalService.getScrollTop();

        //const oaHeaderHeight = generalService.isMobile() && !$('body').hasClass('fixed-body') ? 0 : 80;
        const oaHeaderHeight = !$('body').hasClass('fixed-body') || $('body').hasClass('scroll-down-body') ? 0 : 80;

        return elementTop + winScroll - oaHeaderHeight;
    }

    const handleScrollToContact = () => {
        setTimeout(function () {
            let scrollHeight = getScroll('contact-area');
            generalService.setScrollTop(scrollHeight);
        }, 100);
    }

    const handlePopState = (e) => {
        if (e) {
            e.preventDefault();
        }

        handleInit();
    }

    const handleTimeOut = () => {
        if ($(window).scrollTop() > 0) {
            generalService.setScrollTop(0);
            return;
        }

        handleStopLoading();
    }

    const handleStartTimeOut = () => {
        if (timeoutId.current) {
            clearTimeout(timeoutId.current);
        }
        timeoutId.current = setTimeout(handleTimeOut, 200);
    }

    const handleScroll = (e) => {
        if (!pageLoaded.current) {
            handleStartTimeOut();
            return;
        }
        var scroll = $(window).scrollTop();

        if (scroll > prevScroll.current) {
            if (scrollRef.current > 0) { scrollRef.current = 0; }
        }
        else if (scroll < prevScroll.current) {
            if (scrollRef.current < 0) { scrollRef.current = 0; }
        }

        scrollRef.current += prevScroll.current - scroll;

        // Header fixed
        if (scrollRef.current >= 350 || scroll < 50) {
            $('body').removeClass('scroll-down-body');
        }
        else if (scrollRef.current <= -200) {
            if (scrollToContact.current) {
                handleScrollToContact();
                scrollToContact.current = false;
            }
            $('body').addClass('scroll-down-body');
        }

        if (scroll > 50 && scroll <= 100 && scrollRef.current < 0) {
            $('body').addClass('scroll-down-body');
            $('body').removeClass('fixed-body');
        }
        else if (scroll > 50 && scroll <= 100 && scrollRef.current > 0) {
            $('body').removeClass('scroll-down-body');
            $('body').removeClass('fixed-body');
        }
        else if (scroll > 50) {
            $('body').addClass('fixed-body');
        } else {
            $('body').removeClass('fixed-body');
        }

        prevScroll.current = scroll;
    }

    return (<>
        <div id="fake-overlay" className="fake-overlay d-none"></div>
        {!loading && <Menu />}
        <div>
            {!loading && <>{props.children}</>}
            {loading && <PageLoading />}
        </div>
        {!loading && <Footer />}
        <Cookies />
    </>
    );
}

export const PageLoading = () => {
    return (<>
        <div className="empty-section">
            <div className="fake-overlay-nav"></div>
            <Loading />
        </div>
    </>);
}
