import React, { useEffect, useState, useRef } from "react";
import { Section, FullPageImage, CarouselImages } from '../shared/customHtml';
import { generalService } from '../services/general.service';
import $ from 'jquery';

export const Gallery = () => {
    const galleryPage = window.datasets?.galleryPage ?? {};
    const introImage = galleryPage.introImage;
    const firstCarousel = galleryPage.firstCarousel;
    const secondCarousel = galleryPage.secondCarousel;
    const youtube = window.datasets?.links?.youtube;
    const isMobile = generalService.isMobile();

    const played = useRef(true);
    const intervalId = useRef();

    useEffect(() => {   
        handleScroll();
        window.addEventListener('scroll', handleScroll);

        return () => {
            stoptLoop();
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const startLoop = (video) => {
        if (intervalId.current) { return; }

        intervalId.current = setInterval(tryPlay, 1000);
    }
    const stoptLoop = (video) => {
        if (intervalId.current) {
            clearInterval(intervalId.current);
        }
    }

    const tryPlay = () => {
        let video = document.querySelector("video");

        if (!video.paused) {
            return;
        }

        video.muted = true;

        const promise = video.play();
        if (promise !== undefined) {
            promise.then(() => {
            }).catch(error => {
                video.muted = true;
                video.play();
            });
        } 
    }

    const handleScroll = (e) => {
        if (isMobile) {
            return;
        }

        var scroll = $(window).scrollTop();
        if (scroll == 0) {
            played.current = false;
        }

        if (played.current) { return; }

        let video = document.querySelector("video");
        video.setAttribute('webkit-playsinline', 'webkit-playsinline');

        if (video) {
            let videoTop = video.getBoundingClientRect()?.top ?? 0;
            var _height = generalService.getWindowSize()?.height ?? parseInt(window.innerHeight);
            if (videoTop < _height) {
                startLoop();        
                played.current = true;
            }
        }
    }
    return (
        <div>
            <Section>
                <FullPageImage
                    src={introImage}
                    overlay={true}
                    title={"Galerie DYMY Properties"}
                />
            </Section>

            {firstCarousel && <CarouselImages src={firstCarousel} controls={true} indicators={false} />}

            {secondCarousel && <CarouselImages src={secondCarousel} controls={true} indicators={false} />}

            {!isMobile && <>
                <video playsInline webkit-playsinline="webkit-playsinline" preload="auto" autoPlay muted loop src={youtube}>
                </video>
            </>}
            
        </div>
    );
}
