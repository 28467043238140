import React, { useEffect, useState } from "react";
import { Section, FullPageImage, ApartmentPageImage } from '../shared/customHtml';
import { useNavigate } from "react-router-dom";
import { generalService } from '../services/general.service';
import { Loading } from '../shared/loading';

export const Apartments = () => {
    const navigate = useNavigate();
    const [hasLoading, setHasLoading] = useState(true);
    const [dataSource, setDataSource] = useState([]);

    const apartmentsPage = window.datasets?.apartmentsPage ?? {};
    const introImage = apartmentsPage.introImage;
    const image1 = apartmentsPage.image1;
    const image2 = apartmentsPage.image2;
    const image3 = apartmentsPage.image3;
    const image4 = apartmentsPage.image4;

    var has2camere = dataSource.filter(x => x.type?.toLowerCase() == "2camere" && !x.soldOut)?.length > 0;
    var hasDuplex = dataSource.filter(x => x.type?.toLowerCase() == "duplex" && !x.soldOut)?.length > 0;
    var has3camere = dataSource.filter(x => x.type?.toLowerCase() == "3camere" && !x.soldOut)?.length > 0;
    var hasStudio = dataSource.filter(x => x.type?.toLowerCase() == "studio" && !x.soldOut)?.length > 0;

    useEffect(() => {
        generalService.getApartments().then(response => {
            if (response) {
                setDataSource(response);
            }
            setHasLoading(false);
        });
    }, []);

    const goToHelper = (item) => {
        let data = generalService.encodeUrlBase64(JSON.stringify(item));
        navigate('/tipurideapartamente?q=' + data);
    }

    const goToStudios = () => {
        goToHelper({
            introImage: image1,
            type: 'studios'
        });
    }

    const goToTwoRooms = () => {
        goToHelper({
            introImage: image2,
            type: 'tworooms'
        });
    }

    const goToDuplexes = () => {
        goToHelper({
            introImage: image4,
            type: 'duplexes'
        });
    }

    const goToThreeRooms = () => {
        goToHelper({
            introImage: image3,
            type: 'threerooms'
        });
    }

    return (
        <div>
            <Section>
                <FullPageImage
                    src={introImage}
                    overlay={true}
                    title={"Alege tipul de apartament!"}
                />
            </Section>

            {hasLoading && <Loading />}

            <div className="row m-0 p-0">
                <div className="col-12 col-sm-6 p-0 click">
                    <ApartmentPageImage
                        src={image1}
                        title={"Studio"}
                        titleClassName={"small-img"}
                        soldOut={!hasStudio}
                        onClick={goToStudios}
                    />
                </div>
                <div className="col-12 col-sm-6 p-0 click">
                    <ApartmentPageImage
                        src={image2}
                        title={"2 Camere"}
                        titleClassName={"small-img"}
                        soldOut={!has2camere}
                        onClick={goToTwoRooms}
                    />
                </div>
                <div className="col-12 col-sm-6 p-0 click">
                    <ApartmentPageImage
                        src={image3}
                        title={"3 Camere"}
                        titleClassName={"small-img"}
                        soldOut={!has3camere}
                        onClick={goToThreeRooms}
                    />
                </div>
                <div className="col-12 col-sm-6 p-0 click">
                    <ApartmentPageImage
                        src={image4}
                        title={"Duplex"}
                        titleClassName={"small-img"}
                        soldOut={!hasDuplex}
                        onClick={goToDuplexes}
                    />
                </div>                
            </div>
        </div>
    );
}
