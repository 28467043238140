import React, { useEffect, useState, useReducer, useRef } from "react";
import { Navbar, NavbarBrand, Nav, NavItem, NavLink } from 'reactstrap';
import Button from 'react-bootstrap/Button';
import $ from 'jquery';
import { generalService } from '../services/general.service';
import { useLocation, useNavigate } from "react-router-dom";

export const Menu = () => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        /*let pathname = location?.pathname?.toLowerCase() ?? '';

        if (pathname == '/contact') {
            scrollToContact.current = true;
           handleScrollToContact();
        }
        else {
            handleScrollToTop();         
        }

        // refresh menu
        render();*/

    }, [location]);

    const handleScrollToTop = () => {
        setTimeout(function () {
            // scroll top
            generalService.setScrollTop(0);
        }, 200);
    }



    const handleOpenSideBar = () => {
        $('#sidebar').addClass('opened');
        $('body').addClass("overflow-hidden");
    }

    const goTo = (url) => {
        navigate(url);
    }

    return (<>
        <DesktopMenu goTo={goTo} />
        <TabletMenu handleOpenSideBar={handleOpenSideBar} goTo={goTo} />
        <MobileMenu handleOpenSideBar={handleOpenSideBar} goTo={goTo} />
        <SideBar goTo={goTo} />
    </>
    );
}

const DesktopMenu = ({ goTo }) => {
    const phone = generalService.parsePhoneNumber(window.datasets?.contact?.phone);
    const phoneDisplay = generalService.formatPhoneNumber(phone);

    const getNavItemClassName = (routeName) => {
        let pathname = window.location.pathname?.toLowerCase() ?? '';
        if (pathname.indexOf(routeName) >= 0) {
            return "custom-link active";
        }

        return "custom-link";
    }

    return (<div className="d-desktop">
        <Navbar expand="md" id="navbar" className="align-p">
            <NavbarBrand onClick={() => goTo("/")} className="nav-logo-containter">
                <img className="navbar-logo" src="/images/symbol-white.svg" alt="DYMY PROPERTIES" />
                <img className="navbar-logo-large" src="/images/logo-white.svg" alt="DYMY PROPERTIES" />
            </NavbarBrand>
            <Nav className="ms-auto" navbar>
                <NavItem>
                    <NavLink onClick={() => goTo("/apartamente")} className={getNavItemClassName('apartament')}>Apartamente</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink onClick={() => goTo("/galerie")} className={getNavItemClassName('galerie')}>Galerie</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink onClick={() => goTo("/contact")} className={getNavItemClassName('contact')}>Contact</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink href={`tel:${phone}`} className="custom-call">{phoneDisplay}</NavLink>
                </NavItem>
            </Nav>
        </Navbar>
    </div>);
}

const TabletMenu = ({ handleOpenSideBar, goTo }) => {   
    const phone = generalService.parsePhoneNumber(window.datasets?.contact?.phone);

    return (<div className="d-tablet">
        <Navbar expand="md" id="navbar" className="align-p">
            <NavbarBrand onClick={() => goTo("/")} className="nav-logo-containter">
                <img className="navbar-logo" src="/images/symbol-white.svg" alt="DYMY PROPERTIES" />
                <img className="navbar-logo-large" src="/images/logo-white.svg" alt="DYMY PROPERTIES" />
            </NavbarBrand>
            <Nav className="ms-auto" navbar>
                <NavItem>
                    <NavLink className="custom-link" onClick={handleOpenSideBar}>Meniu</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className="navbar-icon-container" href={`tel:${phone}`}>
                        <img className="navbar-icon fixed" src="/images/call-button-black.svg" alt="..." />
                        <img className="navbar-icon" src="/images/call-button-white.svg" alt="..." />
                    </NavLink>
                </NavItem>
            </Nav>
        </Navbar>
    </div>);
}

const MobileMenu = ({ handleOpenSideBar, goTo }) => {
    const phone = generalService.parsePhoneNumber(window.datasets?.contact?.phone);
    
    return (<div className="d-mobile">
        <Navbar expand="sm" id="navbar" className="align-p">            
            <Nav className="mr-auto" navbar>
                <NavItem>
                    <NavLink className="custom-link mobile-link" onClick={handleOpenSideBar}>Meniu</NavLink>
                </NavItem>              
            </Nav>

            <NavbarBrand onClick={() => goTo("/")} className="mobile-link nav-logo-containter">
                <img className="navbar-logo" src="/images/symbol-white.svg" alt="DYMY PROPERTIES" />
                <img className="navbar-logo-large" src="/images/logo-white.svg" alt="DYMY PROPERTIES" />
            </NavbarBrand>

            <Nav className="ml-auto" navbar>
                <NavItem>
                    <NavLink className="mobile-link navbar-icon-container" href={`tel:${phone}`}>
                        <img className="navbar-icon fixed" src="/images/call-button-black.svg" alt="..." />
                        <img className="navbar-icon" src="/images/call-button-white.svg" alt="..." />
                    </NavLink>
                </NavItem>
            </Nav>
        </Navbar>
    </div>);
}

const SideBar = ({ goTo }) => {

    const handleCall = () => {
        const phone = generalService.parsePhoneNumber(window.datasets?.contact?.phone);

        window.open(`tel:${phone}`, '_self');
        handleClose();
    }

    const handleClose = () => {
        $('#sidebar').removeClass('opened');
        $('body').removeClass("overflow-hidden");
    }
    
    const handleGoTo = (url) => {
        handleClose();
        goTo(url);
    }

    const getNavItemClassName = (routeName) => {
        let pathname = window.location.pathname?.toLowerCase() ?? '';
        if (pathname.indexOf(routeName) >= 0) {
            return "nav-link custom-link active";
        }

        return "nav-link custom-link";
    }

    const downloadBrochure = () => {
        handleClose();
        generalService.downloadBrochure();
    }

    return (
        <div id="sidebar" className="sidebar align-p">
            <div className="sidebar-header">
                <div>
                    <a onClick={handleClose}> <img src="/images/close-button-white.svg" alt="..." /></a>                     
                </div>
                <div>
                    <a onClick={() => handleGoTo("/")}> <img className="logo" src="/images/logo-white.svg" alt="..." /></a>                   
                </div>
                <div>
                    <a onClick={handleCall}> <img src="/images/call-button-white.svg" alt="..." /></a>                      
                </div>
            </div>

            <ul className="navbar-nav">
                <li className="nav-item">
                    <a className={getNavItemClassName('apartament')} onClick={() => handleGoTo("/apartamente")}>Apartamente</a>
                </li>
                <li className="nav-item">
                    <a className={getNavItemClassName('galerie')} onClick={() => handleGoTo("/galerie")}>Galerie</a>
                </li>  
                <li className="nav-item">
                    <a className={getNavItemClassName('contact')} onClick={() => handleGoTo("/contact")}>Contact</a>
                </li>  
            </ul>

            <div className="sidebar-footer align-p">
                <Button variant="info" className="w-100" onClick={downloadBrochure}>Descarcă broșura</Button>
            </div>
        </div>
    );
}