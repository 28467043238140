export const validatorService = {
    validateField,
    validateChecked,
    validateRequired,
    validateEmail,
    validateText,
    validatePhone,
    validateAlphaNumeric
};

function validateChecked(value) {
    return value === true;
}

function validateRequired(text) {
    return text != undefined && text != null && text.toString() != '';
}

function validateEmail(email) {
    if (!email) { return true; }

    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

function validateText(text) {
    if (!text) { return true; }

    return /^[^<>%@!.?$0-9]*$/.test(text);
}

function validatePhone(text) {
    if (!text) { return true; }

    return /^(\+4|004)?(07[0-8]{1}[0-9]{1}|02[0-9]{2}|03[0-9]{2}){1}?([0-9]{3}){2}$/.test(text);
};

function validateAlphaNumeric(text) {
    if (!text) { return true; }

    return /^[a-zA-Z0-9\.\,\;\)\(\-\s]+$/.test(text);
}

function validateField(field) {

    if (field.validations) {
        for (let i = 0; i < field.validations.length; i++) {

            switch (field.validations[i]) {
                case 'required':
                    if (!validatorService.validateRequired(field.value)) {
                        return `Campul ${field.label} este obligatoriu.`;
                    }
                    break;

                case 'checked':
                    if (!validatorService.validateChecked(field.value)) {
                        return `Campul este obligatoriu.`;
                    }
                    break;

                case 'text':
                    if (!validatorService.validateText(field.value)) {
                        return `Campul ${field.label} accepta doar litere.`;
                    }

                case 'alphanumeric':
                    if (!validatorService.validateAlphaNumeric(field.value)) {
                        return `Campul ${field.label} accepta doar litere si cifre, fara diacritice.`;
                    }
                    break;

                case 'phone':
                    if (!validatorService.validatePhone(field.value)) {
                        return `Campul ${field.label} nu este valid.`;
                    }
                    break;

                case 'email':
                    if (!validatorService.validateEmail(field.value)) {
                        return 'Formatul accepta doar email.';
                    }
                    break;

                default:
                    break;
            }
        }
    }

    return '';
}
