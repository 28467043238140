import React from "react";
import { Section, FullPageImage, TermsParagraph } from '../shared/customHtml';
import { generalService } from '../services/general.service';

export const CookiePolicy = () => {
    const introImage = window.datasets?.homePage?.introImage;
    const phone = generalService.parsePhoneNumber(window.datasets?.contact?.phone);
    const phoneDisplay = generalService.formatPlusPhoneNumber(phone);
    const email = window.datasets?.contact?.email;

    return (
        <div>
            <Section>
                <FullPageImage
                    src={introImage}
                    overlay={true}
                    home={true}
                    title={"Politica cookie"}
                />
            </Section>

            <div className="terms align-p">
                <TermsParagraph
                    title="Protecția vieții private și a datelor cu caracter personal"
                >
                    DYMY BUILDING S.R.L. procedează, pe site-ul www.dymyproperties.ro, la înregistrarea datelor tehnice, și anume cookie-urile.
                </TermsParagraph> 

                <TermsParagraph
                    title="Ce este un cookie?"
                >
                    Fișierele cookie sunt fișiere de dimensiune limitată, în general formate din litere și numere, depuse de un server web într-un browser al unui utilizator pentru a trimite informații de stare acelui browser și, în mod similar, pentru a obține astfel de informații din browser. Informațiile despre stare pot fi, de exemplu, un identificator de sesiune, o limbă, o dată de expirare, un domeniu de răspuns etc.
                </TermsParagraph>

                <TermsParagraph
                    title="De ce utilizăm cookie-uri?"
                >
                    www.dymyproperties.ro utilizează cookie-uri pentru a oferi utilizatorilor o experiență optima, adaptată preferințelor dvs. personale. Prin utilizarea cookie-urilor, ne asigurăm că nu trebuie să introduceți aceleași informații de fiecare dată când vizitați site-ul nostru web. Modulele cookie sunt, de asemenea, utilizate pentru a optimiza performanța site-ului. De exemplu, acestea facilitează navigarea și vă ajută să găsiți mai rapid conținutul concret (exemplu: zona de cautare, bugetul alocat).
                </TermsParagraph>

                <TermsParagraph
                    title="Cum pot dezactiva cookie-urile?"
                >
                    Puteți seta browserul de Internet să dezactiveze modulele cookie. Rețineți că dacă dezactivați modulele cookie, numele dvs. de utilizator nu va mai fi salvat pe site-ul www.dymyproperties.ro. Deși majoritatea browser-elor sunt setate implicit și să accepte instalarea de cookie-uri, utilizatorul poate, în cazul în care doreste, să accepte toate cookie-urile sau sa le respinge în mod sistematic. De asemenea, poate seta browser-ul (pentru fiecare terminal se pot face manipulări: tablete, smartphone-uri, laptop, PC etc.) pentru a accepta sau a refuza cookie-urile de la caz la caz înainte de instalare. De asemenea, orice utilizator poate șterge regulat cookie-urile de pe terminalul său prin intermediul browserului său. Pentru gestionarea cookie-urilor și alegerilor utilizatorilor, configurația fiecărui browser este diferită. Este descris în meniul de ajutor al browserului, care permite utilizatorului să știe cum să-și schimbe dorințele pentru cookie-uri. De exemplu:

                    <ul>
                        <li>
                            Pentru Internet Explorer ™:&nbsp; 
                            <a href="https://support.microsoft.com/fr-fr/help/17442/windows-internet-explorer-delete-manage-cookies" target="_blank">
                                https://support.microsoft.com/fr-fr/help/17442/windows-internet-explorer-delete-manage-cookies
                            </a>
                        </li>
                        <li>
                            Pentru Edge ™:&nbsp;
                            <a href="https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy" target="_blank">
                                https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy
                            </a>
                        </li>
                        <li>
                            Pentru Safari ™:&nbsp;
                            <a href="https://www.apple.com/legal/privacy/en-ww/cookies/" target="_blank">
                                https://www.apple.com/legal/privacy/en-ww/cookies/
                            </a>
                            &nbsp;și&nbsp;
                            <a href="https://www.apple.com/legal/privacy/en-ww/" target="_blank">
                                https://www.apple.com/legal/privacy/en-ww/
                            </a>
                        </li>
                        <li>
                            Pentru Chrome ™:&nbsp;
                            <a href="https://support.google.com/chrome/answer/95647?hl=ro&hlrm=ro" target="_blank">
                                https://support.google.com/chrome/answer/95647?hl=ro&hlrm=ro
                            </a>
                        </li>
                        <li>
                            Pentru Firefox ™:&nbsp;
                            <a href="https://support.mozilla.org/en/kb/activate-deactivate-cookies-preferences" target="_blank">
                                https://support.mozilla.org/en/kb/activate-deactivate-cookies-preferences
                            </a>
                        </li>
                    </ul>
                    Astfel, utilizatorul își poate seta browserul să refuze cookie-urile. În acest sens, atenția utilizatorului este atrasă asupra faptului că, prin setarea browser-ul să refuze cookie-uri, unele caracteristici, pagini, zone de site-uri nu vor fi accesibile, actiune pentru care DYMY BUILDING S.R.L. nu poate fi responsabilă.
                </TermsParagraph>

                <TermsParagraph
                    title="Protecția vieții private și a datelor cu caracter personal"
                >
                    <div className="mb-3">Colectarea și prelucrarea informațiilor personale pe Internet trebuie să respecte drepturile fundamentale ale persoanelor. Prin urmare, www.dymyproperties.ro se angajează la o politică de tratament în conformitate cu legislatia in vigoare privind prelucrarea datelor cu caracter personal. În plus, întotdeauna în conformitate cu legea menționată, orice utilizator al site-ului are dreptul de acces, modificare, rectificare sau ștergere a datelor cu caracter personal care îl privesc. El poate exercita aceste drepturi contactându-ne la coordonatele indicate mai jos.</div>
                    <div className="mb-3">DYMY BUILDING S.R.L.</div>
                    <div className="mb-3">CUI: RO36726188; Inregistrata la Oficiul Registrul Comertului sub numarul: J13/2661/2016</div>
                    <div className="mb-3">ADRESA:&nbsp;
                        <a href="https://www.google.com/maps/place/Strada+Rotterdam+2,+Constan%C8%9Ba/@44.1994887,28.6506511" target="_blank">
                        MUN. CONSTANTA, STR. ROTTERDAM, NR. 2, ET. PARTER, AP.4
                    </a></div>

                    <div className="text-semibold">Telefon:&nbsp; <a href={`tel:${phone}`}>{phoneDisplay}</a></div>
                    <div className="text-semibold">{email}</div>
                    <div className="text-semibold">www.dymyproperties.ro</div>

                </TermsParagraph> 
            </div>
        </div>
    );
}