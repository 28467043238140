import React, { useEffect, useState, useRef, useReducer } from "react";
import {
    Section, FullPageImage, InputField, CheckboxField, ErrorField,
    CarouselImages, ElementIcon, Paragraph
} from '../shared/customHtml';
import { Loading } from '../shared/loading';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import $ from 'jquery';
import { generalService } from '../services/general.service';
import { requestService } from '../services/request.service';
import { useNavigate } from "react-router-dom";

export const Home = () => {
    const homePage = window.datasets?.homePage ?? {};
    const introImage = homePage.introImage;
    const carousel = homePage.carousel;

    return (
        <div>
            <Section>
                <FullPageImage
                    src={introImage}
                    overlay={true}
                    home={true}
                    titleClassName={"home"}
                    title={"Lux dincolo de limite."}
                />
                <EmailForm />
            </Section>

            {carousel && <CarouselImages src={carousel} controls={false} indicators={true} />}

            <Section3 />
        </div>
    );
}


export const EmailForm = () => {
    const formValues = useRef({});
    const [, render] = useReducer(p => !p, false);
    const [error, setError] = useState(false);
    const [modalItem, setModalItem] = useState();
    const [hasLoading, setHasLoading] = useState(false);

    const handleSendEmail = () => {
        let _formValues = formValues?.current ?? {};

        if (formValues.current.errors?.length > 0 ||
            !_formValues.name ||
            !_formValues.phone ||
            !_formValues.email ||
            !_formValues.message ||
            !_formValues.agree)
        {
            setError(true);
            return;
        }

        setHasLoading(true);

        requestService.post(
            {
                methodName: '/api/V1/Email/SendEmail',
                body: {
                    Name: _formValues.name,
                    Phone: _formValues.phone,
                    Email: _formValues.email,
                    Message: _formValues.message,
                    Agree: _formValues.agree
                }
            })
            .then(
                response => {
                    handleClose();
                    setHasLoading(false);                   

                    setModalItem({
                        success: true,
                        message: response?.message
                    });
                },
                error => {
                    setHasLoading(false);

                    setModalItem({
                        success: false,
                        message: error?.message
                    });
                });


    }

    const handleOpen = () => {
        $('#email-form').addClass('align-p opened');
        $('body').addClass("overflow-hidden");
        $('#fake-overlay').removeClass("d-none");

        // scroll top
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    const handleResetForm = () => {
        if (formValues.current) {
            formValues.current = {};
            setError(false);
            render();
        }
    }

    const handleClose = () => {
        handleResetForm();
        $('#email-form').removeClass('align-p opened');
        $('body').removeClass("overflow-hidden");
        $('#fake-overlay').addClass("d-none");
    }
    const handleCloseModal = () => {
        if (modalItem?.success) {
            handleClose();
        }

        setModalItem(null);
    }

    return (
        <>
            {hasLoading && <Loading />}
            {modalItem && <Modal
                show={true}
                centered
            >
                <Modal.Header closeButton={false} >
                    <Modal.Title>{modalItem?.success ? 'Succes!' : 'Atentie!'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {modalItem?.message}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" className="w-100" onClick={handleCloseModal}>OK</Button>
                </Modal.Footer>
            </Modal>}

            <div className="d-mobile-tablet">
                <Button variant="light" className="mobile-email-btn align-l" onClick={handleOpen}>Cere ofertă</Button>
            </div>

            <div id="email-form" className="email-form">
                <div className="row d-mobile-tablet">
                    <a onClick={handleClose}> <img src="/images/close-button-white.svg" alt="..." /></a>
                </div>

                <div className="row">
                    <span className="form-title">Programează o vizionare <br /> sau solicită o ofertă.</span>
                </div>

                <div className="row">
                    <div className="col-12 col-lg-6 m-field">
                        <InputField name="name" label="nume" formValues={formValues} validations={["required"]} />
                    </div>
                    <div className="col-12 col-lg-6 m-field">
                        <InputField name="phone" label="tel." formValues={formValues} validations={["required", "phone"]} />
                    </div>
                    <div className="col-12 m-field">
                        <InputField name="email" label="e-mail" formValues={formValues} validations={["required", "email"]} />
                    </div>
                    <div className="col-12">
                        <InputField name="message" label="mesaj" formValues={formValues} validations={["required"]} />
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <CheckboxField name="agree" label="Sunt de acord cu Politica de confidențialitate." formValues={formValues} validations={["checked"]} />
                    </div>
                    {error && <>
                        <div className="col-12">
                            <ErrorField text="Trebuie să selectați toate câmpurile pentru a trimite." />
                        </div>
                    </>}
                </div>

                <div className="row">
                    <div className="col-12">
                        <Button variant="light" className="w-100" onClick={handleSendEmail}>Trimite</Button>
                    </div>                   
                </div>

            </div>

        </>);
}

const Section3 = () => {
    const navigate = useNavigate();
    const phone = generalService.formatPlusPhoneNumber(window.datasets?.contact?.phone);
    const email = window.datasets?.contact?.email;

    const homePage = window.datasets?.homePage ?? {};
    const image1 = homePage.image1;
    const image2 = homePage.image2;
    const image3 = homePage.image3;
    const image4 = homePage.image4;
    const image5 = homePage.image5;
    const image6 = homePage.image6;
    const image7 = homePage.image7;
    const image8 = homePage.image8;

    const goToApartments = () => {
        navigate('/apartamente');
    }
    const goToGallery = () => {
        navigate('/galerie');
    }
    const downloadBrochure = () => {
        generalService.downloadBrochure();
    }
    const goToGoogleMaps = () => {
        generalService.goToGoogleMaps();
    }

    return (<div>
        <ElementIcon rows={3} className="d-desktop-4k" />

        <div className="row m-0 align-p mobile-align-np">
            <div className="col-md-6 col-12">
                <Paragraph className="paragraph-vertical paragraph-mobile-center">
                    <div className="line"></div>
                    <div className="paragraph-title">Luxul are o <br /> nouă adresă!</div>
                    <div className="paragraph-text">Proiectul rezidențial include 40 unități locative: garsoniere, apartamente cu 2 și 3 camere și 2 duplexuri cu 4 camere.</div>
                    <div><Button variant="dark" className="" onClick={goToApartments}>Apartamente</Button></div>
                </Paragraph>
            </div>
            <div className="col-md-6 col-12 p-0">
                <div className="w-100">
                    <img src={image1} className="auto-page-image" alt="page image" />
                    <div className="align-np position-relative">
                        <ElementIcon rows={2} className="element-icon-br" />
                        <ElementIcon rows={4} className="element-icon-tr-40" />
                    </div>
                </div>
            </div>
        </div>

        <div className="row m-0 align-p">
            <div className="col-12">
                <Paragraph className="m-auto text-center paragraph-medium paragraph-padding paragraph-center">
                    <div className="line"></div>
                    <div className="paragraph-title">DYMY Properties</div>
                    <div className="paragraph-text">40 apartamente în care se îmbină eleganța clasică cu rezistența.</div>
                    <div><Button variant="dark" className="" onClick={downloadBrochure}>Descarcă Broșura</Button></div>
                </Paragraph>
            </div>
        </div>

        <div className="row m-0 p-0">
            <div className="col-12 col-sm-6 p-0">
                <img src={image2} className="auto-page-image" alt="page image" />
            </div>
            <div className="col-12 col-sm-6 p-0">
                <img src={image3} className="auto-page-image" alt="page image" />
            </div>
            <div className="col-12 col-sm-6 p-0">
                <img src={image4} className="auto-page-image" alt="page image" />
            </div>
            <div className="col-12 col-sm-6 p-0">
                <img src={image5} className="auto-page-image" alt="page image" />
            </div>
        </div>


        <div className="position-relative">
            <ElementIcon rows={6} className="element-icon-tl" />
        </div>

        <div className="row m-0 align-p">
            <div className="col-12">
                <Paragraph className="m-auto text-center paragraph-medium paragraph-padding paragraph-center">
                    <div className="line"></div>
                    <div className="paragraph-title">Str. Budapesta 1C</div>
                    <div className="paragraph-text">Aflat pe Str. Budapesta 1C, în Tomis Plus Constanța, proiectul îți oferă apartamente funcționale și generoase, de materiale superioare și de un confort absolut.</div>
                    <div><Button variant="dark" className="" onClick={goToGallery}>Galerie</Button></div>
                </Paragraph>
            </div>
        </div>

        <div className="image-text-overlay-container">
            <img src={image6} className="auto-page-image" alt="page image" />
            <div className="image-text-container">
                <Paragraph className="paragraph-vertical align-p paragraph-100 paragraph-mobile-center">
                    <div>
                        <div className="img-paragraph-title">40</div>
                        <div className="img-paragraph-subtitle">UNITĂȚI LOCATIVE</div>
                    </div>
                    <div>
                        <div className="img-paragraph-title">48</div>
                        <div className="img-paragraph-subtitle">LOCURI DE PARCARE</div>
                    </div>
                    <div>
                        <div className="img-paragraph-title">42</div>
                        <div className="img-paragraph-subtitle">TERASE SPAȚIOASE</div>
                    </div>
                </Paragraph>
            </div>
        </div>

        <div className="row m-0 p-0" id="contact-area-container">
            <div className="col-md-6 col-12 p-0">
                <img src={image7} className="auto-page-image" alt="page image" />
            </div>
            <div className="col-md-6 col-12 p-0" id="contact-area">
                <div className="position-relative">
                    <img src={image8} className="auto-page-image" alt="page image" />
                    <div className="image-full-container">
                        <Paragraph className="m-auto text-center paragraph-100 paragraph-padding paragraph-center paragraph-vertical">
                            <div className="line"></div>
                            <div className="paragraph-title">Contact</div>
                            <div className="paragraph-text bold">
                                <div>Str. Budapesta Nr. 1C</div>
                                <div>Tomis Plus, Constanta</div>
                            </div>
                            <div className="paragraph-text bold">                                
                                <div>{email}</div>
                                <div>{phone}</div>
                            </div>
                            <div><Button variant="dark" className="" onClick={goToGoogleMaps}>Vezi Harta</Button></div>
                        </Paragraph>
                    </div>
                </div>

            </div>
        </div>
    </div>);
}
