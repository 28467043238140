import React from "react";
import { Section, FullPageImage, TermsParagraph } from '../shared/customHtml';
import { generalService } from '../services/general.service';

export const PrivacyPolicy = () => {
    const introImage = window.datasets?.homePage?.introImage;
    const phone = generalService.parsePhoneNumber(window.datasets?.contact?.phone);
    const phoneDisplay = generalService.formatPlusPhoneNumber(phone);
    const email = window.datasets?.contact?.email;
    const site = "www.dymyproperties.ro";

    return (
        <div>
            <Section>
                <FullPageImage
                    src={introImage}
                    overlay={true}
                    home={true}
                    title={"Politica de confidențialitate"}
                />
            </Section>

            <div className="terms align-p">
                <TermsParagraph
                    title="Cine suntem"
                >
                    <div className="mb-3">DYMY BUILDING S.R.L., cu sediul social în Municipiul Constanta, Strada Rotterdam, Nr. 2, Etaj Parter, Ap. 4, Judet Constanta, inregistrata la oficiul registrul comertului sub numarul J13/2661/2016, CUI: RO36726188, email: {email}, numar telefon: <a href={`tel:${phone}`}>{phoneDisplay}</a> (denumita in continuare „societatea” sau „platforma” sau „operatorul”) este operator de date cu carcater personal.</div>
                    <div className="mb-3">Prezenta politica are rolul de informare a persoanelor vizate cu privire la conditiile in care sunt procesate datele cu caracter personal de catre societate. Aceasta politica este valabila pentru toate activitatile specifice ale societatii realizate prin intermediul site-ulu {site}.</div>
                    <div className="mb-3">Folosirea serviciilor oferite de societate se poate face doar dupa luarea la cunostinta a acestor politici.</div>
                    <div>Platforma nu este destinata minorilor sub 16 ani si nu poate fi utilizata de acestia.</div>
                </TermsParagraph>

                <TermsParagraph
                    title="Definiții"
                >
                    În cadrul prezentei convenții (precum șI în anexele șI a altor documente conexe), termenii menționațI vor avea sensurile specificate mai jos:
                    <ul>
                        <li>
                            ”contract” – contractul încheiat între părțI șI anexele la acesta;
                        </li>
                        <li>
                            ”date cu caracter personal ale beneficiarului/ reprezentantului legal/ reprezentantului convențional” – toate datele cu caracter personal, indiferent de forma sau mediul lor, care sunt: I) furnizate fie de beneficiar, fie de reprezentantul legal sau convențional al beneficiarului ii) furnizate, provenite, aduse la cunoștință ăn scris sau verbal de către asigurătorul autovehicului supus reparației sau un alt asigurător direct implicat sau de către instituțiile/autoritățile/entitățile publice (secții de poliție, parchete de pe lângă instanțe, instanțe etc.);
                        </li>
                        <li>
                            ”servicii” – serviciile furnizate de către prestator în conformitate cu obiectul contractului;
                        </li>
                        <li>
                            ”autoritate de supraveghere” – înseamnă o autoritate publică independentă instituită de un stat membru având competența de supraveghere a protecției datelor cu caracter personal din ue în a cărei jurisdicție îșI are sediul șI efectuează prelucrarea datelor cu caracter personal prestatorul, în calitate de operator, sau persoana împuternicită de către prestator;
                        </li>
                        <li>
                            ”prelucrare” – înseamnă orice operațiune sau set de operațiuni efectuate asupra datelor cu caracter personal sau asupra seturilor de date cu caracter personal, cu sau fără utilizarea de mijloace automatizate, cum ar: colectarea, înregistrarea, organizarea, structurarea, stocarea, adaptarea sau modificarea, extragerea, consultarea, utilizarea, divulgarea prin transmitere, diseminarea sau punerea în orice alt mod, alinierea sau combinarea, restricționarea, ștergerea sau distrugerea;
                        </li>
                        <li>
                            ”operator” – persoana juridică, cum este în situația de față prestatorul sau fizică care, singură sau împreună cu altele stabilește scopurile șI mijloacele de prelucrarea datelor cu caracter personal;
                        </li>
                        <li>
                            ”persoană împuternicită de operator” – înseamnă persoana fizică sau juridică, autoritatea publică, agenția sau un alt organism care prelucrează datele cu caracter personal, în numele operatorului;
                        </li>
                        <li>
                            „consimțământ” al persoanei vizate - înseamnă orice manifestare de voință liberă, specifică, informată șI lipsită de ambiguitate a persoanei vizate prin care aceasta acceptă, printr-o declarație sau printr-o acțiune fără echivoc, ca datele cu caracter personal care o privesc să fie prelucrate;
                        </li>
                        <li>
                            „încălcarea securității datelor cu caracter personal” - înseamnă o încălcare a securității care duce, în mod accidental sau ilegal, la distrugerea, pierderea, modificarea, sau divulgarea neautorizată a datelor cu caracter personal transmise, stocate sau prelucrate într-un alt mod, sau la accesul neautorizat la acestea;
                        </li>
                        <li>
                            ”consimțământ” al persoanei vizate ( beneficiarului ) înseamnă orice manifestare de voință liberă, specifică, informată șI lipsită de ambiguitate a persoanei vizate prin care aceasta acceptă, printr-o declarație sau printr-o acțiune fără echivoc, ca datele cu caracter personal care o privesc să fie prelucrate.
                        </li>
                        <li>
                            ”regulament” inseamna regulamentul (ue) 2016/679 privind protecția persoanelor fizice în ceea ce privește prelucrarea datelor cu caracter personal șI privind libera circulație a acestor date șI de abrogare a directivei 95/46/ce.
                        </li>
                    </ul>
                </TermsParagraph>

                <TermsParagraph
                    title="Prevederi cu caracter general"
                >
                    <div className="mb-3">Intrucat pretuim confientialitatea informatiilor dvs. Operatorul se obligă să respecte dispozițiile prezentei politici, precum șI prevederile menționate în regulament șI a normelor prevăzute în dreptul intern în ceea ce privește prelucrarea datelor cu caracter personal, securitatea șI confidențialitatea acestora.</div>
                    <div>In cazul in care vom modifica aceasta politica, va vom anunta pe această pagină șI vă vom informa prin e-mail sau prin platforma noastră. Dacă nu suntețI de acord cu politica noastra de confidentialitate a datelor sau nu veti fi de acord cu modificările acesteia, vă putețI închide contul accesând setarea contului șI selectând ștergerea contului sau puteti solicita stergerea datelor cu caracter personal din platforma.</div>
                </TermsParagraph>
                
                <TermsParagraph
                    title="Obiectul, durata, natura, scopul, tipul de date cu caracter personal"
                />
                
                <TermsParagraph
                    title="Obiectul prelucrării"
                >
                    <div>Prelucrarea datelor persoanelor care completeaza unul dintre formularele de contact din site-ul {site} sau contacteaza telefonic, in scopul solicitarii mai multor detalii sau a unei oferte concrete pentru o anumita proprietate aflata la promovare.</div>
                </TermsParagraph>

                <TermsParagraph
                    title="Durata prelucrării"
                >
                    <div>Prelucrarea datelor cu caracter personal se va efectua: pe durata in care vanzarea imobilului aferent anuntului postat de dvs nu sa realizat inca si/sau atat timp aveti un cont activ pe platforma si/sau pana la momentul in care ne veti solicita sa nu mai prelucram datele continute in anuntul dvs., dar nu mai mult de 3 ani de la data inceperii prelucrarii prin primirea anuntului sau preluarea acestuia de pe site-uri pubice terte.</div>
                </TermsParagraph>

                <TermsParagraph
                    title="Natura prelucrării"
                >
                    <div>Prelucrarea datelor cu caracter personal se face in temeiul prestarii activitatilor noastre specifice, in baza relatiei contractuale dintre noi sau in baza unui interes legitim.</div>
                </TermsParagraph>

                <TermsParagraph
                    title="Scopul prelucrării"
                >
                    <div>Deservirea interesului legitim al utilizatorului site-ului plusimobiliare.Ro care solicita in scris sau telefonic o oferta de prestari servicii sau de cumparat/inchiriat o proprietate aflata in portofoliul agentiei noastre imobiliare.</div>
                </TermsParagraph>

                <TermsParagraph
                    title="Temeiul legal al prelucrarii"
                >
                    <ul>
                        <li>Executarea contractului;</li>
                        <li>Obligatie legala;</li>
                        <li>Interes legitim.</li>
                        <li>Consimtamant.</li>
                        <li>Tipul de date cu caracter personal</li>
                        <li>Persoane ce utilizeaza platforma pentru postarea anunturilor sau persoanele ale caror anunturi sunt preluate de pe platforme publice similare</li>
                        <li>Nume si/sau prenume</li>
                        <li>Adresa de email</li>
                        <li>Numar de telefon</li>
                        <li>Versiunea sistemului de operare, identificatorii unici.</li>
                        <li>Locul din care postati anuntul prin identificare protocol internet (ip) a dispozitivului folosit.</li>
                        <li>Sesiunile utilizatorilor, pentru a stoca preferințele de selecție a limbilor șI a vă oferi reclame relevante prin utilizarea cookie-uri.</li>
                        <li>(posibil) date financiare care pot include contul bancar șI detalii privind cardul de plată (banca emitentă, valabilitatea cardului, titularul cardului, etc.).</li>
                        <li>Reprezentantul (-ii) legali șI/sau convenționali a/ai clientilor</li>
                        <li>Date de identificare (nume, prenume, funcție);</li>
                        <li>Date de contact (domiciliu, număr de telefon, adresă de e-mail) </li>
                        <li>Categoriile de persoane vizate</li>
                    </ul>
                    <div className="mb-3"></div>
                    <div className="mb-3"></div>
                </TermsParagraph>

                <TermsParagraph
                    title="De la cine colectam datele"
                >
                    <div className="mb-3">De la persoana vizate cand datele sunt furnizate direct, spre exemplu cand completati un formular de contant din site-ul {site}.</div>
                    <div>De la terti cum ar fi google cand primim informatii cu privire la utilizarea platformei noastre.</div>
                </TermsParagraph>

                <TermsParagraph
                    title="Datele personale sensibile"
                >
                    <div className="mb-3">Datele sensibile se referă la date care includ detalii despre rasa sau etnia dvs., convingeri religioase sau filosofice, viața sexuală, orientarea sexuală, opinii politice, apartenența la sindicate, informații despre sănătate dvs. ȘI date genetice șI biometrice. Nu colectăm nicio informație despre convingeri, viața sau orientare sexuală, opiniile politice, aparteneța la sindicate, informații despre sănătate, date genetice sau biometrice sau despre infracțiuni.</div>
                    <div className="mb-3">In conformitate cu legislatia fiscala si contabila, putem fi obligati sa colectăm date ce pot fi considerate sensibile precum c.N.P., precum contul bancar șI cardul bancar (inclusiv banca emitentă, valabilitatea cardului, utilizatorul cardului). Prelucrarea acestor date se poate face doar in situatia in care suntem obligati printr-o prevedere legala.</div>
                    <div className="mb-3 text-semibold">Temeiul de drept al utilizării datelor cu caracter personal</div>
                    <div>Prestatorul va prelucra datele cu caracter personal în vederea:</div>
                    <ul>
                        <li>Îndeplinirii obligațiilor în conformitate cu cererea utilizatorului care ne-a contactat prin formular de contract sau telefonic, din site-ul {site}.</li>
                        <li>Atunci când există un interes legitim al societatii, acest interes legitim neputand sa prevaleze intereselor legitime ale dumneavoastra.</li>
                        <li>Atunci cand am obtinut consimtamantul dvs. Pentru furnizarea de mesaje publicitare prin email sau sms, altele decat mesajele in legatura cu care aveti un interes direct.</li>
                    </ul>
                    <div>Prelucrările acestor date, în ceea ce privește frecvența si modalitatea, sunt:</div>
                    <ul>
                        <li>La momentul completarii unui formular de contact pe site-ul nostru;</li>
                        <li>La momentul in care incheiem un contract;</li>
                        <li>Dacă este necesar în interesele noastre legitime (sau cele ale unei terțe părțI) șI interesele șI drepturile dvs. Fundamentale nu depășesc acele interese.</li>
                        <li>Dacă trebuie să respectăm o obligație legală sau statutară.</li>
                        <li>Cand va trimitem o informare prin sms sau email.</li>
                        <li>Cand primim informatii despre vizitele dvs. Si interactiunea cu serviciile furnizate de alte entitati cand vizitati serviciile altor furnizori care includ reclame, module cookie sau tehnologii asemanatoare. </li>
                    </ul>
                    <div className="mb-3 text-semibold">Consimțământul</div>
                    <div className="mb-3">Având în vedere din cele precizate din prezenta politica rezultă că nu prelucrăm, în general, date personale cu caracter sensibil, astfel cum sunt de finite in regulamentnu este necesar consimțământul dvs. Expres.</div>
                    <div className="mb-3">Prin completarea unui formular de contact din site sau efectuarea unui apel telefonic din platforma, consideram ca este interesul dvs legitim de a primi informari periodice din parte societatii prin sms sau email cu privire la anunturi ce ar putea fi de interes pentru dvs. Daca la orice moment considerati ca interesul dvs legitim s-a schimbat si nu mai doriti primirea informatiilor periodice, aveti oricand optiunea de a va exprima optiunea de a nu mai primi acele mesaje.</div>
                    <div className="mb-3">De asemenea, cu privire la trimiterea comunicărilor către dvs. Prin e-mail sau mesaj text, avețI dreptul de a vă manifesta optiunea de a nu primi informari de la noi în orice moment prin trimiterea unui e-mail către noi la adresa de e-mail {email}.</div>
                    <div className="mb-3">Vom obține consimțământul dvs. Expres înainte de a distribui datele dvs. Cu caracter personal către orice terță parte sau pentru trimitrea unor mesaje în scopuri de marketing si inafara interesului dvs legitim.</div>
                    <div>Ne puteți cere sau putețI cere terțelor părți să nu mai trimită mesaje de marketing în orice moment prin trimiterea către noi a unui e-mail la {email} în orice moment sau prin activarea funcției de dezabonare din continutul mesajului.</div>
                </TermsParagraph>

                <TermsParagraph
                    title="Modificarea scopului"
                >
                    <div className="mb-3">Prestatorul va utiliza doar datele cu caracter personal în scopul în care au fost obținute, cu excepția cazului în care, în mod rezonabil este necesar ale utiliza în alt scop șI motivul este compatibil cu scopul original. Dacă dorițI să aflațI mai multe despre cum prelucrarea în noul scop este compatibilă cu scopul original, vă rugăm să ne trimitețI un e-mail la {email}.</div>
                    <div className="mb-3">Dacă trebuie să folosim datele dvs. Personale într-un scop care nu are legătură cu scopul pentru care am colectat datele, vă vom notifica șI vă vom explica motivele legale pentru procesare.</div>
                    <div className="mb-3">Putem procesa datele dvs. Cu caracter personal fără consimțământul sau informarea beneficiarului, reprezentantului legal sau convențional, dacă acest lucru este necesar si/sau permis în conformitate cu legea.</div>
                    <div className="mb-3 text-semibold"> Divulgarea datelor dvs. Cu caracter personal</div>
                    <div>Este posibil să distribuim sau să divulgăm datele dvs. Cu caracter personal către terțele părțI indicate mai jos:</div>
                    <ul>
                        <li>Agentii imobiliare</li>
                        <li>Societatățile de asigurare</li>
                        <li>Furnizorii de servicii care oferă servicii it șI de administrare a sistemului.</li>
                        <li>Consultanții profesioniști inclusiv avocațI, auditori, contbili șI asiguratori care oferă servicii de consultanță necasare desfasurarii activitatii noastre.</li>
                        <li>Administrația fiscală șI vamală, organele de reglementare șI alte autoritățI cu sediul în romania care cer raportarea activităților de procesare în anumite circumstanțe (spre exemplu secții de poliție, parchete de pe lângă instanțe de judecată, instanțe etc.).</li>
                    </ul>
                    <div>Le cerem tuturor terțelor părțI cărora le transferam datele să respecte securitatea datelor dvs. Cu caracter personal șI să le prelucreze în conformitate cu legea. Vom permite acestor terțe părțI să proceseze datele dvs. Cu caracter personal în scopurile specificate șI în conformitate cu prevederile legale numai daca primim asigurari cu privire la legalitatea si conformitatea prelucrarii datelor cu caracter personal.</div>
                </TermsParagraph>

                <TermsParagraph
                    title="Transferuri internaționale"
                >
                    <div>Nu transferăm datele dvs. Cu caracter personal în afara spațiului economic european (see).</div>
                </TermsParagraph>

                <TermsParagraph
                    title="Securitatea datelor"
                >
                    <div className="mb-3">Prestatorul a implementat măsurile adecvate de securitate pentru a împiedica ca datele dvs. Cu caracter personal să fie pierdute, utilizate sau accesate accidental, modificate sau divulgate în mod neautorizat. De asemenea, limităm accesul la datele dvs. Personale acelor angajațI, agențI, contractanțI șI altor terțe părțI care au o nevoie comercială de a cunoaște datele respective. Aceștia vor procesa datele dvs. Personale la instrucțiunea noastră șI se supun obligației de confidențialitate.</div>
                    <div className="mb-3">Am implementat proceduri pentru a trata orice încălcare suspicioasă a datelor cu caracter personal șI vă vom notifica pe dvs. ȘI orice autoritate de reglementare competentă cu privire la încălcare, atunci când suntem obligațI legal să acționăm astfel.</div>
                    <div>Putem pastra datele dvs format fizic sau format electronic. În unele circumstanțe, putem anonimiza datele dvs. Cu caracter personal (astfel încât să nu mai fie asociate cu dvs.) în scopuri de cercetare sau statistice, caz în care putem utiliza aceste informații nelimitat fără să vă informăm.</div>
                </TermsParagraph>

                <TermsParagraph
                    title="13.  Drepturile legale"
                >
                    <div>În completarea celor menționate in prezenta politica, în anumite circumstanțe, persoana vizata are anumite drepturi în conformitate cu legile privind protecția datelor cu privire la datele dvs. Cu caracter personal. Aceste includ:</div>
                    <ul>
                        <li>Dreptul de acces</li>
                    </ul>
                    <div>Ne putețI cere:</div>
                    <ul>
                        <li>Să confirmăm dacă vă prelucrăm datele personale;</li>
                        <li>Să vă dăm o copie a acelor date;</li>
                        <li>Să vă oferim alte informații despre datele dvs. Personale, precum datele pe care le avem, pentru ce le folosim, cui le dezvăluim, dacă le transferăm în străinătate șI cum le protejăm, cât timp le păstrăm, ce drepturi avețI, cum putețI face o plângere, de unde am luat datele dvs. ȘI dacă am efectuat orice proces decizional automatizat sau creare de profiluri, în măsura în care informațiile nu v-au fost deja furnizate în această politică.</li>
                    </ul>
                </TermsParagraph>

                <TermsParagraph
                    title="Dreptul de rectificare"
                >
                    <div className="mb-3">Ne putețI cere să rectificăm datele personale inexacte. Este posibil să căutăm să verificăm acuratețea datelor înainte de rectificarea acestora.</div>
                    <div className="mb-3">Dreptul de stergere (dreptul de a fi uitat)</div>
                    <div>Ne putețI cere să vă ștergem datele personale, dar doar în cazul în care:</div>
                    <ul>
                        <li>Nu mai sunt necesare pentru scopurile pentru care au fost colectate; sau</li>
                        <li>V-ați retras consimțământul (când prelucrarea datelor a fost bazată pe consimțământ); sau</li>
                        <li>Ca urmare a unui drept întemeiat de a obiecta (vedețI „obiecție” mai jos); sau</li>
                        <li>Au fost prelucrate ilegal; sau</li>
                        <li>Trebuie respectată o obligație legală al cărei subiect este compania.</li>
                    </ul>
                    <div>Nu suntem obligațI să răspundem solicitării dvs. De a vă șterge datele personale dacă prelucrarea datelor dvs. Personală este necesară:</div>
                    <ul>
                        <li>Pentru respectarea unei obligații legale; sau</li>
                        <li>Pentru stabilirea, exercitarea sau apărarea unor drepturi în justiție;</li>
                        <li>Există alte câteva circumstanțe în care nu suntem obligațI să răspundem solicitării dvs. De ștergere, deși acestea două sunt probabil cele mai comune circumstanțe în care vă vom refuza această solicitare.</li>
                    </ul>
                </TermsParagraph>

                <TermsParagraph
                    title="Dreptul de restrictie"
                >
                    <div>Ne puteți cere să vă restricționăm (șI anume, să le păstrăm fără a le folosi) datele personale doar când:</div>
                    <ul>
                        <li>Acuratețea acestora este contestată (vedețI rectificare), pentru a ne permite să le verificăm acuratețea; sau</li>
                        <li>Prelucrarea este ilegală, dar nu doriți să le ștergeți; sau</li>
                        <li>Nu mai sunt necesare pentru scopurile pentru care au fost colectate, dar avem în continuare nevoie de ele pentru a stabili, a exercita sau a apăra drepturi în justiție; sau</li>
                        <li>V-ați exercitat dreptul de a obiecta, iar verificarea motivelor imperioase este în așteptare.</li>
                    </ul>
                    <div>Putem continua să folosim datele dvs. Personale în urma unei cereri de restricție, în cazul în care:</div>
                    <ul>
                        <li>Avem consimțământul dvs.; sau</li>
                        <li>Pentru a stabili, a exercita sau a apăra drepturi în justiție; sau</li>
                        <li>Pentru a proteja drepturile unei alte persoane fizice sau juridice.</li>
                    </ul>
                </TermsParagraph>

                <TermsParagraph
                    title="Dreptul de portabilitate"
                >
                    <div>Ne putețI cere să vă furnizăm datele dvs. Personale într-un format structurat, folosit în mod obișnuit, prelucrabil automat, sau putețI solicita să fie „portate” direct unui alt operator de date, dar în orice caz, doar când:</div>
                    <ul>
                        <li>Prelucrarea este bazată pe consimțământul dvs. Sau pe executarea unui contract cu dvs.; șI</li>
                        <li>Prelucrarea este realizată prin mijloace automate.</li>
                    </ul>
                </TermsParagraph>

                <TermsParagraph
                    title="Dreptul de opozitie"
                >
                    <div className="mb-3">PutețI obiecta la orice prelucrare a datelor dvs. Personale ce au ca temei juridic „interesele noastre legitime”, dacă credețI că drepturile șI libertățile dvs. Fundamentale au prioritate față de interesele noastre legitime.</div>
                    <div>Odată ce ațI obiectat, avem oportunitatea de a vă demonstra că avem interese legitime convingătoare, ce au prioritate față de drepturile șI libertățile dvs.</div>
                </TermsParagraph>

                <TermsParagraph
                    title="Dreptul de a formula o plangere"
                >
                    <div>Aveți dreptul de a depune o plângere la autoritatea națională de supraveghere a prelucrării datelor cu caracter personal. Vă rugăm să încercațI să rezolvațI orice problemă discutând, în primă instanță, cu noi, deșI avețI dreptul de a contact autoritatea de supraveghere în orice moment.</div>
                </TermsParagraph>

                <TermsParagraph
                    title="Dreptul de a va retrage consimtamantul"
                >
                    <div className="mb-3">Aveti dreptul de a va retrage consimtamantul acordat in situatia in care societatea prelucreaza datele cu caracter personal in baza acestuia.</div>
                    <div className="mb-3">Persoana vizata nu va plăti un comision sau orice altă taxă pentru a accesa datele dvs. Cu caracter personal (sau de a exercita oricare dintre celelalte drepturi). TotușI, societatea, în calitate de operator poate impune un onorariu rezonabil dacă cererea formulată este vădit nefondată, repetitivă sau excesivă. Alternativ, prestatorul poate refuza să respectăm cererea primită în aceste circumstanțe.</div>
                    <div className="mb-3">Societatea are dreptul de a cere anumite informații pentru a putea confirma identitatea persoanei vizate care a efectuat solicitarea șI a asigura, în siguranță, datele personale (sau de exercita oricare dintre celelalte drepturi). Aceasta este o măsură de securitate pentru a asigura că datele cu caracter personal nu sunt divulgate persoanelor care nu au dreptul să le primească. Vă putem contacta pentru a vă cere informații suplimentare cu privire la cererea dvs. De a grăbi răspunsul nostru.</div>
                    <div>Societatea va depune diligențele necesare pentru a răspunde la toate cererile legitime în termen de o lună. Ocazional, este posibil să dureze mai mult de o lună dacă cererea prestatorului este foarte complexă sau persoana vizată a formulat mai multe cereri. În acest caz, prestatorul vă va notifica șI vă va ține la curent.</div>
                </TermsParagraph>

                <TermsParagraph
                    title="14. Sa tinem legatura"
                >
                    <div>Primul punct de contact pentru toate chestiunile ce reies din această politică, inclusiv solicitări de exercitare a drepturilor subiecților, este ofițerul nostru de protecție a datelor. Ofițerul de protecție a datelor poate fi contactat în următoarele moduri:</div>
                    <ul>
                        <li>Prin e-mail la {email}</li>
                        <li>Prin posta la adresa Municipiul Constanta, Strada Rotterdam, Nr. 2, Etaj Parter, Ap. 4, Judet Constanta</li>
                    </ul>
                    <div className="mb-3">Dacă avețI o plângere sau suntețI îngrijorat în legătură cu modul în care vă folosim datele personale, vă rugăm să ne contactațI, în primă instanță, pe noi șI noi vom încerca să rezolvăm problema cât mai curând posibil.</div>
                    <div className="mt-5 text-semibold">Va multumim!</div>
                </TermsParagraph>
            </div>
        </div>
    );
}

