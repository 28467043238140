const getRequestOptions = (methodType, options) => {
    return {
        method: methodType,
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: "include",
        body: options.body ? JSON.stringify(options.body) : null
    };
}

const handleResponse = (response) => {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (data && data.message) {
                return Promise.reject(data);
            }

            return Promise.reject({
                message: response.statusText
            });
        }

        return data;
    });
}

const request = (methodType, options) => {
  
    if (options.queryString) {
        var queryParams = Object.getOwnPropertyNames(options.queryString);
        options.methodName += '?';

        queryParams.forEach(param => options.methodName += param + '=' + (options.queryString[param] != undefined ? encodeURIComponent(options.queryString[param]) : '') + '&');
        if (options.methodName.slice(-1) === '&') {
            options.methodName = options.methodName.slice(0, -1);
        }
    }

    const requestOptions = getRequestOptions(methodType, options);

    return fetch(options.methodName, requestOptions)
        .then(handleResponse);
}

// call with methodName, queryString, body
export const requestService = {
    get: (options) => request('GET', options),
    post: (options) => request('POST', options)
};
