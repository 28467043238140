import React, { useEffect, useState } from "react";
import $ from 'jquery';

export const Loading = (props) => {
    const text = props.text ?? "Vă rugăm să aștepțati..";

    useEffect(() => {
        $('body').addClass("overflow-hidden");
        return () => {
            $('body').removeClass("overflow-hidden");
        };
    }, []);

    return (
        <div>
            <div className="k-loading-overlay"></div>
            <div className="k-loading-container">
                <img src={"/images/loading.svg"} className="k-loading-img" alt={"loading..."} />
                <span className="k-loading-txt">{text}</span>
            </div>
        </div>
    );
};
