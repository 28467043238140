import React, { useReducer } from "react";
import { ElementIcon } from '../shared/customHtml';
import { generalService } from '../services/general.service';
import { useNavigate } from "react-router-dom";

export const Cookies = (props) => {
    const navigate = useNavigate();
    const [, render] = useReducer(p => !p, false);

    const key = "allowCookies";
    const value = localStorage.getItem(key);

    if (value == "true") {
        return null;
    }

    const goToCookies = () => {
        navigate("/cookie");
    }

    const handleAllowCookie = () => {
        localStorage.setItem(key, "true");
        render();
    }

    return (<>
        <div className="cookies-container align-l align-r">
            <div>
                <div>Acest site folosește cookies.&nbsp;
                    <a onClick={goToCookies}>Navigând în continuare, vă exprimați acordul asupra folosirii acestora.</a></div>
                <div><img onClick={handleAllowCookie} src="/images/cookies-button-white.svg" alt="..." /></div>
            </div>
        </div>
    </>
    );
}